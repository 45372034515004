const exports = {
    " ": " ",
    go_back: "返回",
    notifications: "通知",
    settings: "设置",
    logout: "登出",
    logged_out_error: "您已经签退了，请登录后再试。",
    logged_out_success: "您已成功签退。",
    confirm_logout_text: "您确认要签退吗?",
    ok: "确定",
    error: "错误",
    no: "无",
    yes: "有",
    language: "语言",
    account: "帐户",
    login: "登录",
    cancel: "取消",
    store: "商家",
    products: "产品",
    restaurant: "餐饮",
    groupsale: "团购",
    travel: "旅游",
    shopping: "购物",
    service: "服务",
    save: "保存",
    confirm: "确认",
    success: "成功",
    loading: "载入中",
    warning: "警告",
    general_error: "发生了未知错误，请稍后再试",
    orders: "我的订单",
    click_to_input_address: "请点击选择地址",
    en: "English",
    zh: "简体中文",
    "zh-Hant": "繁體中文",
    kr: "한국어",
    jp: " 日本語",
    es: "Español",
    "fr-lang": "Français",
    de: "Deutsch",
    edit: "编辑",
    input: "自定义",
    years: "年",
    quarters: "季",
    months: "月",
    weeks: "周",
    days: "天",
    days_2: "日期",
    time: "时间",
    times: "时间",
    hours: "小时",
    minutes: "分钟",
    seconds: "秒",
    milliseconds: "毫秒",
    today: "今天",
    tomorrow: "明天",
    yesterday: "昨天",
    this_week: "本星期",
    last_week: "上個星期",
    this_month: "这个月",
    last_month: "上个月",
    last: "上个",
    next: "下一个",
    now: "現在",
    mon: "星期一",
    tue: "星期二",
    wed: "星期三",
    thu: "星期四",
    fri: "星期五",
    sat: "星期六",
    sun: "星期天",
    mo: "星期一",
    tu: "星期二",
    we: "星期三",
    th: "星期四",
    fr: "星期五",
    sa: "星期六",
    su: "星期天",
    share: "分享",
    dont_show_this_again: "不再显示此内容",
    update_order: "Update Order",
    your_order: "您的订单",
    schedule_for_later: "预订",
    ready_in_asap: "准备时间{start}-{end}分钟",
    when_would_you_like_to_order: "您想何时要",
    no_shipping_address_selected: "未提供配送地址。",
    change: "更改",
    start_order: "开始下单",
    order_for: "订购",
    jan: "1月",
    feb: "2月",
    mar: "3月",
    apr: "4月",
    may: "5月",
    jun: "6月",
    jul: "7月",
    aug: "8月",
    sep: "9月",
    oct: "10月",
    nov: "11月",
    dec: "12月",
    view_more: "查看更多",
    viewing: "正在查看",
    show_filters: "显示过滤器",
    reward_history_filter: "积分记录过滤器",
    date_range: "日期范围",
    my_referrals: "我的推荐",
    how_to_do_it: "如何领取奖励积分及奖励积分规则",
    how_to_do_it_referrals:
        "1. 向您的朋友或社交媒体分享带您个人推荐码的链接或二维码，如果他们之前没有被其他人推荐并且他们使用您提供的链接进行下单，他们将成为您的被推荐人。{br}{br}2. 来自被推荐人当前的订单和未来的订单，首单可获得奖励积分为: 税前消费金额 * {v2pCombined}; 举例：税前消费$ {exampleBasePurchase},那么奖励积分为 {exampleBasePurchase} * {v2pCombined} = {exampleResultPoints}；首单之后的每一个订单您都将获得奖励积分，即税前消费金额 * {v2pCustomerRef}；举例：税前消费$ {exampleBasePurchase}，奖励 {exampleBasePurchase} * {v2pCustomerRef} = {exampleResultPoints3} 积分。如果您分享的链接让已被别人绑定的推荐人使用下单，那么您将获得一次性奖励积分为: 税前消费金额 * {v2pOrderRef}；举例：税前消费$ {exampleBasePurchase}，一次性奖励 {exampleBasePurchase} * {v2pOrderRef} = {exampleResultPoints2} 积分。{br}{br}3. 比如：A推荐B，B尚未成为别人的被推荐人，B下单后A将获得B首单的税前消费金额 * {v2pCombined} 作为奖励积分，之后B每次下单，A都可以获得B税前消费金额 * {v2pCustomerRef} 作为奖励积分。如果B已经成为别人的被推荐人，B通过A分享的链接下单后，A将一次性获得B的税前消费金额 * {v2pOrderRef} 作为奖励积分。{br}{br}注解：本店每 {p2v} 个奖励积分可兑换 $1。",
    copy_ref_url: "复制推荐网址",
    total: "总计",
    generate_qr_code: "生成二维码",
    ref_orders: "订单",
    income: "收入",
    check_how_much_money_earned: "查看您赚了多少钱",
    check_how_much_rewards_earned: "您获得了多少奖励积分",
    redeemable: "可兑换",
    total_redeemed: "已兑换的总数",
    sales: "销售",
    check_how_many_sales: "查看您的销售量",
    rewards_history: "奖励历史",
    check_daily_history: "查看您的每日奖励交易",
    orders_info: "查看您的朋友下了多少个订单",
    referrals_info: "推荐信息",
    referrals_info_desc: "这是您推荐的客户信息。点按每个按钮即可获取相应订单和销售信息",
    admin_reward_action: "管理员奖励积分",
    points_redeemed: "用奖励积分支付订单",
    customer_invitation: "推荐客户积分",
    order_invitation: "推荐订单积分",
    admin_credit: "商家奖励积分",
    sales_rule: "购买订单积分",
    admin_revert_points: "提取积分",
    refunded_order: "订单退款",
    ref_successfully_copied: "推荐码成功复制",
    click_tips_with_helper_text: "小费(点击金额可修改)",
    click_set_points: "点击兌換積分",
    click_to_search_address: "点击搜索地址",
    click_please_input_shipping_addresss: "点击输入配送地",
    click_please_input_pickup_addresss: "点击选择自取位置",
    click_please_input_payment_method: "点击选择支付方式",
    click_to_show_on_map: "点击打开地图",
    tap_to_search_address: "点按搜索地址",
    sorry_search: "暂无搜索结果",
    try_again_search: "换个关键词试试",
    recent_searches: "最近搜索",
    recommendations: "推荐",
    deals: "优惠",
    view_all: "查看全部",
    products_not_available: "以下产品现在不能购买，将从购物车中移除:",
    shopping_cart_empty: "您的购物车是空的",
    not_available_dialog_title: "现在不可下单",
    not_available_dialog_captain: "此产品仅在下面的时间范围可以下单",
    sorry_product_not_available: "抱歉，此产品仅在以下时间提供",
    feedback: "用户反馈",
    download_qr: "下载二维码",
    download_qr_mobile: "长按图片保存至相册",
    download_poster: "下载海报",
    download_poster_mobile: "长按图片保存至相册",
    rate_the_product: "产品评价",
    are_you_sure_cancel_review: "您确定要舍弃此评论吗？",
    submit: "重置",
    review_order_placeholder: "您的评价将帮助别人做出更好的选择",
    review_submitted_message: "感谢您的参与，评价将在验证后发布",
    please_input_or_select: "请输入或选择满意的理由",
    review_service: "评价商家服务",
    rate_hint: "点按星标进行评分",
    not_available: "不可用",
    current_not_available: "目前不可用",
    allow_reservation: "可预约",
    has_tv: "有电视",
    has_free_wifi: "无线上网",
    has_outdoor_seating: "户外座位",
    kid_friendly: "适合儿童",
    group_friendly: "适合聚会",
    business_friendly: "商业氛围",
    street_parking: "路边停车",
    free_parking: "免费停车",
    paid_parking: "收费停车",
    noise_level: "噪音程度",
    average: "一般",
    loud: "有点吵",
    very_loud: "很吵",
    casual: "非正式的",
    business_casual: "商务休闲",
    smart_casual: "时尚休闲",
    business: "商务",
    semi_formal: "半正式",
    has_alcohol: "酒类服务",
    environment: "环境",
    attire: "穿着",
    upscale: "高端",
    classy: "优雅",
    hipster: "潮人",
    intimate: "私密性",
    no_alcohol: "不提供酒精饮料",
    beer_and_wine: "提供酒水",
    full_bar: "标准酒吧",
    write_your_review_here: "写下你对本商家的评价",
    rate_the_store: "商家评价",
    more_information: "更多信息",
    merchant_information: "商家信息",
    additional_pages: "其它页面",
    store_info: "商家详情",
    store_overview: "商家概况",
    opens: "营业",
    closes: "打烊",
    current_store: "当前商家",
    order: "订单",
    my_order: "我的订单",
    deliver_to: "配送地址",
    delivery: "配送",
    pickup: "自取",
    takeout: "自取",
    options: "选规格",
    check_out: "结账",
    pick_one: "必选1项",
    pick_amount: "必选{min}项",
    pick_range_up_to: "可选{max}项",
    pick_range_required: "必选{min}-{max}项 ",
    pick_optional: "可选",
    pick_one_with_name: "{nm}需要必选一项",
    pick_amount_with_name: "{nm}需要必选{min}项",
    pick_range_up_to_with_name: "{nm}最多选择{max}项",
    pick_range_required_with_name: "{nm}需要选择{min}-{max}项",
    up: "起",
    search_products: "搜索产品",
    current_menu: "当前菜单",
    all_menus: "全部菜单",
    search: "搜索",
    discount_by: "满{y}减{x}",
    discount_by_without_price: "减{x}",
    store_closed: "打烊",
    are_you_sure_clear_cart: "您确定要清空购物车吗?",
    are_you_sure_keeping_cart: "购物车中有已保存的物品。您想将物品保留在购物车中吗？",
    clear_the_cart: "清空购物车",
    keep_the_cart: "保留",
    clear: "清空购物车",
    results: "搜索结果",
    search_results: "{x} 個搜索結果",
    pending_acceptance: "待接单",
    delivery_time: "配送时间",
    eatin: "堂食",
    order_pickup_time: "取货时间: {time}",
    order_delivery_time: "配送时间: {time}",
    discount_amount: "已减{currentDiscount}",
    remaining: "再买{remaining}减{amount}",
    remaining_delivery: "还差{remaining}起送",
    remaining_pickup: " 添加{remaining}待取",
    menu_available_at: "此菜单有效时间为:@{ available }…",
    please_input_name: "请输入名字",
    no_address_head: "没有已保存的地址",
    no_address_message: "您目前没有任何地址记录.",
    select_branch: "请选择分店",
    store_on_hold: "抱歉，本店目前暂不接受网上订单",
    out_of_stock: "售罄",
    load_more: "加载更多",
    no_order_message: "当您有新订单或者结束订单后将会在这里出现。",
    no_order: "没有订单记录。",
    create_an_order: "下单",
    no_more_data: "没有更多了",
    no_search_results: "没有搜索结果",
    group_purchase: "惠民团",
    show_more: "显示更多",
    show_less: "收起",
    purchase_history: "购买历史",
    start_time: "开始时间",
    end_time: "结束时间",
    expired: "已经结束",
    offer_expires_in: "距离结束还有: {time}",
    remaining_stock: "库存 {count}",
    people_ordered: "{amt} 人已团",
    offer_starts_in: "接龙开始还有: {time}",
    offer_starts_on: "开始时间: {time}",
    offer_ends_on: "结束时间: {time}",
    select_category: "选择目录",
    selection_error_min: "{option}最少要选{min}项",
    no_giftcard_message: "您拥有的礼品卡将出现在这里。",
    upsell_products: "您可能还喜欢...",
    switch_to_dine_in: "确认堂食",
    switch_to_in_store: "店内下单确认",
    switch_to_dine_in_message: "请输入您的餐桌号以进行堂食点餐，点击取消将保持在{type}订单类型",
    surcharge: "附加费",
    store_surcharge: "附加費",
    order_surcharge: "订单附加费",
    item_total: "商品小计",
    surcharge_paid: "附加费(已支付)",
    surcharge_unpaid: "附加费(未支付)",
    related_id: "相关订单 #",
    related: "相关",
    surcharege_paid_message: "此订单不包括附加费。请参考订单 #{id}",
    surcharge_paid_to_message: "本订单是用来支付订单 #{id}的附加费",
    switch_to_dine_in_confirm: "看来您目前不在商家，您确定是堂食就餐吗？",
    switch_to_in_store_confirm: "看来您目前不在商家，您确定是店内下单吗？",
    pull_to_refresh: "Pull to Refresh",
    no_products_record: "找不到产品.",
    delivery_locations: "配送区域/时间",
    pickup_locations: "自取位置",
    min_amount: "最少金额",
    min_order_amount: "最少订单金额",
    free_delivery_amount: "免费配送金额",
    delivery_times: "配送时间",
    pickup_times: "自取时间",
    time_range: "时间段",
    store_location: "商家位置",
    pickup_locations_schedule: "自取位置/时间",
    roll_over_image_to_zoom_in: "鼠标悬停图片放大",
    about: "关于",
    product_information: "产品简介",
    product_details: "产品详情",
    buy_now: "立即购买",
    see_more_product_details: "查看更多商品详情",
    video: "视频",
    page_of: "第{page}页, 共{totalNumOfPages}页",
    warning_max_purchase_limit_reached: "提醒: 已达到最大购买限制",
    max_purchase_limit_reached_msg: "您最多只能购买{max_sale_qty}个此产品。",
    warning_max_purchase_qty_exceeded_msg: "以下商品已超过最大购买数量。商品数量将被修改为最大可购买数量。{item_list}",
    max_purchase_qty: "最大购买数量: {qty}",
    warning_min_sale_qty_not_met_msg: "以下商品不满足最低购买数量。",
    min_purchase_qty: "最少订购数量: {qty}",
    remove_items: "删除商品",
    increase_item_quantities: "增加商品数量",
    store_offline_message: "抱歉，我们目前不接受在线订单。 请稍后再试。",
    order_payment_status_error: "商家已经接单，请当面支付。",
    order_comment_status_error: "商家已经开始准备此订单，如果有任何更改，请联系商家。电话：{phone}.",
    oos_items_cannot_be_added: "The item(s) {oos_items} are not available and could not be added to the cart.",
    order_cancelled: "订单已取消",
    pending_store_confirmation: "待商家接单",
    pending_customer_confirmation: "待客户确认",
    store_accepted: "商家已接单",
    order_preparing: "商家准备中",
    store_is_preparing: "商家准备中",
    ready_pickup: "已经",
    ready_courier_pickup: "订单已经准备好配送ß",
    rejected_by_courier: " 配送员拒单",
    courier_assigned: " 已安排配送员",
    courier_accepted: "配送员接单",
    courier_heading_to_store: "配送员前往商家",
    courier_arrived_at_store: "配送员抵达商家",
    courier_collected_package: "配送员已取件",
    courier_heading_to_customer: "正在配送",
    courier_arrived_at_customer: "配送员抵达客户处",
    delivered_order: "配送完成",
    order_changes_description: "您的订单已更改。 请参考建议的更改并尽快确认，以免延误您的订单。",
    confirm_item_change: "确认订单更改",
    are_you_sure_reject: "拒绝建议的更改将导致该产品从订单中移除，您确认要继续吗？",
    accepted: "Accepted",
    rejected: "Rejected",
    added: "Added",
    removed: "Removed",
    updated: "Updated",
    updated_an_item: "更改了产品",
    added_an_item: "增加了产品",
    removed_an_item: "移除了产品",
    replaced_an_item: "更换了产品",
    are_you_sure_change_order_confirm: "您确定要确认对此订单所做的更改吗？",
    order_change_return_price_delta: "更改后的订单的总金额为{new_price}，将在完成订时退还差额给您。",
    order_change_pay_price_delta: "更改后的订单的总金额为{new_price}，您的信用卡的最终消费额将为该金额。",
    order_change_pay_new_price:
        "更改后的订单总额是{new_price}，您需要支付差价{price_delta}。请及时支付附加费以免延误您的订单。",
    order_change_reject_all: "您已拒绝所有建议的更改。您确定要取消订单吗？",
    order_change_reject_all_refund: "您已拒绝所有建议的更改。确定要取消订单吗？取消后，付款将获得退款{price_delta}。",
    order_change_refund: "更新后的订单总金额为{new_price}，系统将退还{price_delta}的差价给您。",
    order_again_adds_items: "[再来一单]会自动将相同的商品添加到购物车",
    hide_menu: "隐藏产品",
    show_menu: "显示产品",
    copy: "复制",
    copied_success: "已经拷贝订单号到剪切板",
    shipping_time: "自取时间",
    pickup_time: "自取时间",
    request_due_time: "就绪时间",
    requested_pickup_time: "要求自取时间",
    requested_delivery_time: "要求配送时间",
    ready_pickup_time: "自取就绪时间",
    estimated_delivery_time: "预计送达时间",
    order_time: "下单时间",
    shipping_info: "配送信息",
    order_info: "订单信息",
    table_info: "座位信息",
    serve_time: "预计就绪时间",
    order_detail: "订单详情",
    alipay: "支付宝",
    union_pay: "银联借记卡",
    union_pay_express: "银联信用卡",
    braintree_apple: "Apple Pay",
    braintree_credit_card: "信用卡",
    braintree_paypal: "PayPal",
    braintree_google_pay: "Google Pay",
    paypal_express: "PayPal Express",
    etransfer: "电子或邮件转账",
    emt_description: "下单后用电子或邮件转账付款",
    payment_received: "已付款",
    payment_pending: "待支付",
    payment_cancelled: "付款取消",
    payment_failed: "支付失败",
    emt_payment_note_long: "您为此订单选择了E-Transfer/EMT，请尽早安排付款并通知店家，以免延误您的订单。",
    emt_payment_password: "请将 {amount} 发送至 {emt_account}，将支付密码设置为 {emt_password}",
    emt_payment_account: "请将 {amount} 发送至 {emt_account}",
    free: "无需支付",
    dine_in: "堂食",
    in_store: "店内",
    flatrate: "平价配送",
    freeShipping: "数字资产",
    instantCheckout: "即刻结帐",
    pending: "待接单",
    processing: "处理中",
    payment_review: "付款审查中",
    completed: "已完成",
    canceled: "已取消",
    closed1: "已关闭",
    pending_payment: "等待店家确认",
    holded: "保留",
    cancel_order: "取消订单",
    order_number: "订单 #",
    order_status: "订单状态",
    order_date: "下单时间",
    order_type: "订单类型",
    shipping_method: "运输方式",
    expected_time: "要求时间",
    customer: "用户姓名",
    party_size: "人数",
    delivery_address: "配送地址",
    pickup_location: "自取地址",
    store_discount: "商家折扣",
    coupon: "优惠券",
    shipping_and_handling: "配送费",
    shipping_and_handling_with_distance: "配送费 ({distance})",
    order_total: "总额",
    giftcard_payment: "礼品卡付款",
    points_payment: "积分付款",
    points_payment_with_points: "积分付款 ({x})",
    comments: "订单备注",
    reorder: "再来一单",
    general_order_error: " 发生错误。请回到订单列表页重试。",
    order_unexist: "没有发现订单记录。",
    you: "您",
    cancel_order_warning: "您确定要取消订单吗?",
    cancel_order_success: "您在{store_name} 的订单#{order_id}已经取消。",
    cancel_order_failed: "未能取消订单，请稍后再试。",
    pay_now_unavailable: "网上付款不可用",
    no_online_payment: "抱歉，本商家目前不接受在线支付。请稍后重试或直接与商家联络。",
    pay_now: "现在付款",
    write_review: "写评论",
    write_review_for: "为{name}写评论。",
    overall: "总体",
    update_review: "修改评价",
    add_a_review: "添加评价",
    rate_our_service: "服务评价",
    rate_our_delivery: "配送评价",
    post: "发布",
    requested_time: "要求时间",
    scheduled_time: "预计时间",
    ord_dt: "下单时间",
    exp_dt: "期待时间",
    ord_total: "订单总价",
    s_method: "订单类型",
    p_method: "支付方式",
    start_processing_message: "您确定要标注订单为正在处理吗?",
    mark_as_complete_message: "您确定要把订单标注为完成吗？",
    cancel_order_message: "您确定要取消订单吗?",
    complete: "已完成",
    reject: "拒绝",
    total_refunded: "退款总额",
    refund_amount: "退款金额",
    refund_all: "全额退款",
    remark: "备注",
    "edit-shipping-fee-message": "更改运费将导致取消原订单并用修改后的运费创建新订单, 您确定要继续吗？",
    edit_shipping_info: "编辑配送地址",
    additional_info: "额外信息",
    estimatedDeliTime: "预计配送时间",
    exp_time: "要求配送时间",
    "create-new-order-message": "修改订单将会取消此订单，并且创建一个新的订单，您确定要修改吗？",
    order_and_account_information: "订单与客户信息",
    address_info: "地址信息",
    shipping_address: "配送地址",
    payment_and_shipping_method: "付款和送货方式",
    payment_info: "支付信息",
    shipping_handling_info: "配送信息",
    item_order: "订单物品",
    raw_total: "物品小结",
    notes_for_this_order: "订单注释",
    alert_on_notification: "发消息时警告",
    sub_comment: "提交评论",
    modify_discount: "修改折扣",
    add_discount: "添加折扣",
    modify_tips: "修改小费",
    add_tips: "添加小费",
    discount: "折扣",
    modify_shipping_fee: "修改配送费",
    edit_payment: "更改支付",
    edit_extra: "修改信息",
    edit_payment_method: "更改支付方式",
    "select_receipt(s)_to_print": "选择要打印的收据",
    change_payment_to: "您确定要将付款方式更改为",
    handling: "处理",
    coupon_discount: "优惠券折扣",
    phone: "电话号码",
    email: "电子邮件",
    email_receipt: "电邮收据",
    email_receipt_sent_to: "电邮发票已发送至",
    unpaid: "未付款",
    admin: "管理员",
    cust: "客户",
    other_payment: "其他付款",
    access_denied: "抱歉，您无权查看此订单",
    please_pay_now: "订单尚未支付，您可以在线支付。",
    are_you_sure_cancel_order: "您确定要取消订单吗?",
    please_pay_by_x: "请在{x}内完成支付，超时后订单将自动取消",
    reward: "奖励",
    reward_points: "奖励积分",
    shipment_tracking_info: "快递追踪信息",
    carrier: "快递公司",
    tracking_number: "运单号码",
    register: "注册",
    vc_login: "手机验证登录",
    resend: "重新发送",
    pwd_login: "账号登录",
    email_phone_placeholder: "手机号或者邮件地址",
    password: "密码",
    vc: "验证码",
    get_vc: "发送验证码",
    titleS: "够淘",
    alert_login_succeed: "登录成功",
    alert_phone_not_found: "此号码尚未注册",
    alert_too_many_request: "您已达到请求验证码次数的上限。请在 15 分钟后重试。",
    alert_phone_length: "无效的的手机号!",
    alert_phone_vc_not_match: "手机验证码不匹配",
    alert_login_failed_general: "登录失败，请稍后再试。",
    alert_login_failed: "无效的用户名/密码.",
    forget_pwd: "忘记密码",
    state_reg: "如果您尚未在我们的网站上注册，通过短信登录后系统将为您创建一个帐户",
    cos_privacy_statement: "登录即表示您同意Goopter的{cos}和{privacy}",
    cos_privacy_statement_register: "注册后，即表示您同意Goopter的{cos}和{privacy}",
    cos: "使用条款",
    privacy: "隐私政策",
    or_login_with_social: "或用社交帐号登录",
    social_redirect_text: "要使用您的社交帐户登录，您将被重定向到本商家的宿主网站，您要继续吗？",
    social_redirect_text_cart:
        "要使用您的社交帐户登录，您将被重定向到本商家的宿主网站，但是您的购物车中的产品不能被结转，您要继续吗？",
    social_login_failed: "{socialType}账号登录出错，请重试。",
    check_out_as_guest: "匿名下单",
    email_placeholder: "电子邮件",
    c_tn: "用户电话",
    nickname: "昵称",
    alert_invalid_request: "无效的请求",
    alert_phone_exists: "此号码已经使用",
    alert_bind_phone_conflict_title: "号码已经注册",
    alert_bind_phone_phone_exists:
        "这个号码已经在另外一个账户中注册了，如果您需要用这个号码下单的话请签退后用此号码登录再下单；您也可以选择用另外一个号码来下单。",
    alert_bind_phone_phone_exists_guests:
        "这个号码已经在另外一个账户中注册了，如果您需要用这个号码下单的话请用此号码登录再下单；您也可以选择用另外一个号码来下单。",
    alert_bind_phone_conflict:
        "您尝试使用的号码已在另一个帐户中注册，如果要使用该号码下单，点击继续按钮会将当前帐户与此号码绑定，如果您想使用另一个号码下单，请单击取消 然后尝试另一个号码",
    alert_vc_input_time_expired:
        "如果您在1分钟内未收到短信验证码，则表示您的号码不正确或您的号码无法正确接收短信。{br}{br}请确保您使用的是正确的号码或使用其他号码重试。",
    alert_reg_success_text: "您现在可以用您的电话号码登录",
    alert_reg_success_email: " 您现在可以用您的电子邮件地址登录",
    alert_reg_failed: "注册失败.",
    alert_email_length: "无效邮件地址",
    open: "营业中",
    store_popup_sold: "已售",
    distance: "距离",
    prep_time: "准备时间",
    min_delivery_amnt: "最少配送金额",
    max_delivery_dist: "最远配送距离",
    public_notice: "公告",
    discounts: "折扣信息",
    discount_message: "满{price}减{discount}",
    discount_message_without_price: "减{discount}",
    invalid_first_name: "请提供名！",
    invalid_shp_mtd: "结帐不成功。无效配送方式，请尝试另一种配送方式。",
    pay_later_cash_description: "当面现金支付",
    pay_later_card_description: "当面刷卡支付",
    continue: "继续",
    name: "姓名",
    customer_info: "客戶信息",
    please_leave_your_comment: "添加备注",
    order_now: "尽快",
    order_success: "订单已发出",
    view_order: "查看订单",
    secure_connection: "信息将通过加密方式安全传送",
    confirm_order: "确认订单",
    table_number: "桌号",
    back_to_store: "返回商店",
    subtotal: "小计",
    delivery_fee: "配送费",
    total_before_tax: "税前金额",
    tax: "税",
    tips: "小费",
    tips_with_helper_text: "小费(点按金额可修改)",
    quantity: "数量",
    total_paid: "已付款",
    total_due: "应付金额",
    refund: "退款",
    payment_method: "支付方式",
    estimate_shipping_fee: "预估配送费",
    shipping_option: "配送方式",
    pick_shipping_option: "选择一个配送方式",
    add_comment: "添加备注",
    shipping_option_title: "运费: ",
    pay_later: "稍后刷卡支付",
    cash: "稍后现金支付",
    credit_card: "信用卡",
    pay_by_credit_card: "信用卡支付",
    giftcard: "礼品卡",
    points: "积分",
    points_and_giftcard: "积分和礼品卡",
    wechat_pay: "微信支付",
    address_customer_info: "Address Customer Info",
    c2c_regular: "普通配送",
    c2c_express: "快速配送",
    S01: "无需配送",
    S02: "平价配送",
    S03: "表单费率",
    credit_card_added: "已加信用卡",
    map_search_placeholder: "请输入街道地址",
    exceed_delivery_distance_select:
        "提供的送货地址超过了商家的最大配送距离 {delivery_distance} {distance_unit}。 请选择另一个送货地址。",
    exceed_delivery_distance_input:
        "提供的送货地址超过了商家的最大配送距离 {delivery_distance} {distance_unit}。 请选择另一个送货地址。",
    address_not_in_delivery_zone: "提供的送货地址不在商家的送货区域范围内。请选择另一个送货地址。",
    item_oos_all: "购物车中的所有产品都缺货。 请添加其它产品以继续。",
    error_occured: "抱歉，发生错误，请签退后重试。",
    transaction_declined: "交易被拒，请选择另外的支付方式。",
    payment_method_missing: "请填写支付信息!",
    billing_address_missing: "请提供账单地址!",
    giftcard_error: "礼品卡错误，请刷新页面重试。",
    customer_info_missing: "请提供用户信息",
    table_number_missing: "请提供桌号!",
    pickup_time_missing: "請提供自取时间",
    delivery_time_missing: "请提供配送时间",
    delivery_address_missing: "请提供收货地址",
    shipping_method_missing: "请提供配送方式",
    set: "确认",
    invalid_request: "结帐不成功。无效请求。",
    invalid_pay_mtd: "结账不成功。付款方式无效，请尝试另一种付款方式。",
    item_error: "发生了一个错误。你的购物车已被清空，请重新添加产品到购物车后再结账。",
    no_items_error: "购物车里没有任何产品。请添加一些产品后再结算。",
    use_profile_info: "使用账户信息",
    pick_up: "自取",
    delivery_option: "配送方式",
    required: "必填项目",
    first_name_placeholder: "输入您的名字",
    last_name_placeholder: "输入您的姓",
    table_number_placeholder: "输入桌号",
    unit_placeholder: "输入房间号",
    buzz_placeholder: "输入门铃号",
    street_placeholder: "输入街道号",
    city_placeholder: "输入城市名",
    region_placeholder: "输入州名或省名",
    cntry_placeholder: "输入您的国家",
    zipcd_placeholder: "输入您的邮编",
    table_number_required: "请提供桌号",
    no_shipping_options: "所选地址没有配送方法。请选择另一个配送地址。",
    delivery_time_changed: "配送时间已经更改，请选择另一个合适的时间。",
    gift_card_payment: "礼品卡付款",
    expires: "过期",
    allowed_periods_unavailable: "请选择一个合适的送货地址，以获得合理的送货时间。",
    delivery_method_unavailable: "配送已不再适用，请返回商家页面再次结帐。",
    takeout_method_unavailable: "自取已不再适用，请返回商家页面再次结帐。",
    in_store_method_unavailable: "店内下单已不再适用，请返回商家页面再次结帐。",
    agreement_notice: "通过确认购买，我同意Goopter的{conditions_of_use} & {privacy_policy}",
    conditions_of_use: "使用条款",
    privacy_policy: "隐私条款",
    "secure-connection": "信息将通过加密方式安全传送",
    set_payment_method: "请选择支付方式",
    set_billing_address: "设置账单地址",
    set_delivery_address: "设置配送地址",
    set_giftcard: "选择礼品卡",
    set_points: "點按兌換積分",
    redeem_points_dialog_title: "兑换积分",
    redeem_x: "兑换: {x}",
    min_order_required_to_redeem_points: "Minimum order required is {minValue} to redeem points.",
    min_points_required_to_redeem:
        "A minimum of {minPoints} points must be redeemed at a time.{br}Your current points balance: {pointsBalance}",
    points_cannot_be_redeemed: "Points cannot be redeemed at this store.",
    set_customer_info: "输入客户信息",
    set_table_number: "输入桌号",
    set_pickup_time: "选择自取时间",
    set_delivery_time: "选择配送时间",
    set_shipping_method: "设置配送方式",
    address_book: "地址本",
    "no-giftcard-text": "您没有此商家的礼品卡。",
    delete_credit_card_failed: "抱歉，不能删除此卡，请稍后重试。",
    checkout_success: "您的订单已经创建：#{order_id}。当订单状态更新时系统将会发送通知信息给您。",
    new_order_created: "新订单已创建",
    distance_exceeded: "超出距离",
    min_delivery_amt_required: "最低配送金额为{required} (税前不包配送费)，如需配送请再加{gap}。",
    insufficient_fund: "资金不足，请尝试使用其他付款方式。",
    invalid_payment_info: "支付信息无效，请检查您的输入后重试。",
    merchant_not_ready: "商家尚未准备好接受您的支付方式。请稍后再试。",
    shipping_country_text: "可配送到使用以下国家的地址: {countries}",
    apply: "使用优惠券",
    enter_coupon_code: "输入优惠劵代码",
    remove_coupon_alert: "您确定要删除此优惠券吗?",
    replace_coupon_alert: "当前已用的优惠券将被替换，您是否希望继续?",
    invalid_alipay_coupon: "这个优惠券只能选择支付宝支付才有效",
    remove_current_alipay_coupon:
        "当前的购物车里的折扣券只能在选择支付宝支付才有效，如果用其它方式支付的话这个折扣将会失效，您还要更改支付方式吗?",
    use_wechat_dialog_message:
        "要使用微信支付，请在微信里打开商家网站下单。{br}{br}在微信上打开商家网站的选项：{br}1.如果您有已经商家二维码，直接用微信扫码；{br}2.点击页面顶部的商店名称以返回商店页面，然后点击右上角的图标以打开二维码弹出窗口，保存二维码，然后在微信中打开二维码下单。",
    discount_total: "折扣总额",
    first_name_missing: "请输入您的名",
    phone_number_missing: "请输入您的电话号码",
    payment: "支付式",
    select_payment: "选择一个支付方式",
    select_giftcards: "请选择礼品卡",
    special_request: "特殊要求",
    place_order_with_x: "确认下单 - {x}",
    min_type_required_message: "最低{type}金额是{value}，请选购更多产品",
    select_pickup_location: "请选择自取位置",
    please_choose_pickup_location: "请选择自取地点",
    invalid_delivery_zone_title: "超出配送范围",
    invalid_delivery_zone_content: "提供的地址超出了配送范围。请输入其它地址。配送区域和时间如下：",
    please_input_address: "请输入有效地址",
    please_input_pickup_time: "请选择自取时间",
    please_input_delivery_time: "请选择配送时间",
    recommend_a_good_restaurant: "为您挑选了一个好店",
    recommend_a_good_merchat: "为您挑选了一个好商家",
    recommend_a_good_product: "为您挑选了一个好物",
    guest_user: "匿名用户",
    require_utensil: "需要餐具",
    already_have_account: "匿名下单",
    order_success_table: "感谢您的惠顾。订单就绪后，我们会立即带到您的桌子上。",
    order_success_parking_lot: "感谢您的惠顾。订单就绪后，我们会立即带到您的车上。",
    order_success_kiosk: "感谢您的惠顾。订单就绪后，我们会立即通知您。",
    view_special_deals: "查看特别优惠",
    more_items_to_get_special_deals: "当前订单金额不符合特别优惠条件。请添加更多产品。",
    special_deals_for_you: "为您提供的特别优惠",
    add_items: "添加更多",
    discounted_upsell_items_removed_msg: "由于当前订单金额不再符优惠条件，折扣加售商品已被删除。",
    discounted_upsell_items_removed_empty_cart_msg:
        "由于当前订单金额不再符优惠条件，折扣加售商品已被删除。您的购物车现在是空的，请添加产品后继续。",
    menu: "菜单",
    category: "分类",
    categories: "分类",
    store_details: "商家详情",
    store_description: "店家描述",
    reviews: "客户评价",
    rank: "评价",
    customer_reviews: "评论",
    single_selection: "单选",
    optional: "可选",
    pick_varied_options: "选择{count}项",
    sold: "已售{count}",
    only_x_left: "还剩{count}个",
    sold_out: "售罄",
    pick_option: "可选规格",
    contact_vendor: "致电商家",
    call_vendor: "联系商家",
    self_input: "自定义价格",
    coupons: "{couponCount}个优惠券",
    coupons_title: "优惠券",
    redeem: "领取",
    remove: "移除",
    redeemed: "已领取",
    valid_until: "有效期至 {endDate}",
    checkout: "结账",
    shopping_cart: "购物车",
    empty_cart: "清空购物车",
    alert_empty_cart: "您确定要清空购物车吗?",
    discount_title: "折扣信息",
    qr_code: "二维码",
    poster: "海报",
    m: "公尺",
    km: "公里",
    min: "分钟",
    hour: "小时",
    rating: "评分",
    rating_title: "评分",
    base_delivery_fee: "{flatDistance}{distanceUnit}内配送费用",
    min_delivery_amount: "最少配送金额",
    max_delivery_distance: "最远配送距离",
    distance_title: "距离",
    takes_reservations: "预定",
    more_info: "更多信息",
    announcement: "公告",
    no_announcement: "暂无公告",
    buy_x_discount_flat: "满{currency}{price}，减{currency}{discount}",
    buy_x_discount_percent: "满{currency}{price}，减{discount}%",
    search_products_title: "搜索产品",
    search_products_input_placeholder: "请输入关键字搜索...",
    search_products_no_match: "抱歉，没有找到与 '{text}' 相关的产品。",
    alert_delete_product_with_options: "多规格商品只能去购物车删除。",
    store_closed_allowed_preorder: "商家休息中，您可以现在预购。",
    store_closed_allowed_preorder_x_mins_before: "商家目前已打烊。您可以在开门前 {mins} 分钟预订。",
    store_closed_soon_not_allowed_order: "商家即將打烊，目前不接受新訂單。",
    store_closed_soon_allow_preorder_next_day: "商铺即将打烊，仅接受下一个工作日的预订。",
    store_closed_only_accepting_preorder_next_business_day: "商铺已打烊，您可预订下一个工作日的订单。",
    results_colon: "搜索结果: ",
    price: "价格",
    popularity: "人气",
    service_title: "服务",
    product_title: "产品",
    all_title: "全部",
    satisfied_title: "满意",
    unsatisfied_title: "不满意",
    closed: "已打烊",
    discount_rate: "{rate}折",
    invalid_option_selection: "无效选项",
    no_product_options: "没有产品选项",
    single_selection_error: "请选择一个 '{productOption}' 选项",
    pick_ranged_options_error: "'{productOption}' 选项最少选择{count}个",
    pick_fixed_options_error: "请选择{count}个 '{productOption}' 选项",
    add_to_cart: "加入购物车",
    update_cart: "更新购物车",
    total_reviews: "个评价",
    store_total_sold_count: "销量",
    hot_products: "热门产品",
    related_products: "相关产品",
    store_hours: "营业时间",
    delivery_hours: "配送时间",
    add_for_delivery: "差 {currency}{amount} 起送",
    open_in_app: "用App开启",
    qrcode_message: "扫描二维码打开此商店",
    review_submitted_validate: "感谢您提交评价，该评价将在审核后发布。",
    review_submitted: "您的评价已提交。",
    add_update_review: "请添加或更新您的评价",
    post_review_failed: "评价未提交。请刷新您的页面，然后重试。",
    not_accepting_orders: "本店暂不接受网上下单",
    bookmark_sign_in_alert_store: "登录后才能将此商家添加为书签",
    bookmark_sign_in_alert_product: "登录后才能将此产品添加为书签",
    no_store_bookmarks: "没有商家书签",
    no_store_bookmarks_message: "您没有任何商家的书签。点击商家页面右上角的心形图标即可为商店添加书签",
    no_store_bookmarks_button: "添加商家书签",
    no_product_bookmarks: " 没有产品书签",
    no_product_bookmarks_message: "您没有产品的书签。点击产品页上的心形图标将其加上书签",
    no_product_bookmarks_button: "添加产品书签",
    no_group_bookmarks: "没有优惠书签",
    no_group_bookmarks_message: "您没有特价品的书签。 点击特价品页面上的心形图标将其加上书签",
    no_group_bookmarks_button: "添加特价品书签",
    add_item_another_menu_warning: "您的购物车已包含菜单: {menu} 中的菜品，是否要清除购物车并添加此菜品？",
    start_new_cart_question: "新购物车",
    change_menu: "切换菜单",
    direction: "路线",
    website: "网站",
    we_also_recommend: "商家推荐...",
    diff_delivery_price: "最低配送金额{x}",
    discount_info_format: "满{x}减{y}",
    discount_add_x_get_y: "再加{x}可减{y}",
    discount_get_percentage_discount: " 减{x}",
    discount_get_value_discount: " 减{x}",
    discount_not_accepting_order: "本店暂不接受网上下单",
    discount_store_closed_preorder: "商家休息中，您可以预购",
    discount_x_off_add_y_get_z: "已减{x}，再加{y}减{z}",
    discount_x_off: "已减{x}",
    switch_menu_warning_message:
        "所选菜单支持不同的订单类型({new_order_type})。如果继续，购物车中的物品将被清除。您要切换到新菜单吗？",
    logout_confirm_message: "您确定要签退吗?",
    cannot_merge_account: "您的帐户已存在，请使用其他电话号码。",
    duplicate_social_error: "该电话号码已在另一个帐户注册，请使用其它号码。要使用此号码，请签退后用该号码登录。",
    store_has_no_products: "这家商店目前没有产品。",
    error_with_code: "[Error {code}]: {error}",
    unable_to_load_any_data: "无法加载数据",
    unstable_network: "网络速度较慢或不稳定，请检查您的网络连接",
    logout_force_message: "抱歉，您的帐户已从其他设备登录或超时，请重新登录。",
    incorrect_password: "密码错误",
    country_code_error: "国家/地区代码错误",
    incorrect_username_password: "用户名/密码不正确",
    verification_code_expired: "验证码已过期",
    verifcation_code_request_overlimit: "验证码请求超限",
    incorrect_verification_code: "验证码不正确",
    error_fetch_user_profile: "无法获取用户个人资料",
    login_failed: "登录失败",
    profile_update_success: "更新个人资料成功",
    email_update_success: "更新电子邮件成功",
    phone_update_success: "更新电话号码成功",
    failed_fetch_gift_card_detail: "无法获取礼品卡详细信息",
    gift_card_transfer_success: "礼品卡转让成功",
    gift_card_transfer_fail: "无法转让礼品卡",
    record_not_found: "未找到记录",
    failed_fetch_points_detail: "无法获取积分详细信息",
    profile_update_failed: "更新用户个人资料失败",
    email_update_failed: "更新电子邮件失败",
    phone_update_failed: "更新电话号码失败",
    get_verification_code_success: "验证码已发送到您的手机",
    verification_too_many_requests: "验证码已被请求过多次，请稍后再试",
    invalid_table_number: "无效的桌号",
    invalid_party_size: "无效的人数",
    coupon_invalid: "无效的优惠券",
    coupon_valid: "有效优惠券",
    invalid_coupon: "优惠券代码 {couponCode} 无效",
    coupon_applied: "优惠券已应用到购物车",
    invalid_address: "无效地址",
    remaining_stock_header: "库存",
    item_oos: "抱歉，以下商品的库存数量不足：",
    invalid_shipping_method: "无效配送方式，请尝试另一种配送方式。",
    please_input_phone_number: "请输入有效的电话号码",
    please_input_first_name: "请输入名字",
    please_input_last_name: "请输入姓氏",
    please_input_shipping_method: "请输入送货方式",
    please_input_sub_shipping_method: "请输入送货方式",
    please_input_shipping_addresss: "点按输入配送地址",
    please_input_pickup_addresss: "点按选择自取位置",
    please_input_table_number: "请输入桌号",
    please_input_payment_method: "点按选择支付方式",
    please_input_valid_credit_card: "请输入有效的信用卡",
    please_input_billing_address: "请输入帐单地址",
    tap_to_show_on_map: "点按打开地图",
    please_add_some_items: "请添加一些产品",
    please_confirm_tips: "点按设置小费金额",
    please_set_minimum_tips: "请输入最少小费金额",
    please_set_minimum_tips_detail: "为了确保为您的订单找到司机，最低小费金额为${tips_amount}",
    bind_phone: "验证电话号码",
    please_bind_phone: "下单前需要先验证您的电话号码。你想现在验证电话号码吗?",
    go_to_bind: "去验证",
    bind_phone_success: "验证成功",
    invalid_credit_card: "无效信用卡，清选择另外一个支付方式。",
    unsuccessful_checkout: "结帐不成功。请再试一次。",
    price_over_limit: "结账不成功。价格超过付款限额。",
    please_input_belows: "请输入以下缺少的部分",
    unable_to_change_here: "无法在此更改",
    please_select_on_previous_page: "请在上一页选择",
    invalid_card_number: "无效的卡号",
    invalid_zipcd: "无效的邮政编码",
    click_one_more_time_to_deselect: "再点击一次取消选择",
    paypal_payment_success_title: "支付成功",
    paypal_payment_success_text: "您的付款已收到。下面将返回到订单页面。",
    error_payment_failed: "支付失敗",
    internal_server_error: "抱歉，出现意外错误，请稍后再试或直接与商家联系。",
    unexpected_error: "发生意外错误，请稍后再试",
    are_you_sure_remove_gift_card: "你确定要删除这张礼品卡？",
    giftcard_pays_order_total: "所选的礼品卡将支付此订单，无需其它付款方式。",
    points_pays_order_total: "您的积分已经支付订单总额，无需其它付款方式。",
    giftcard_points_pays_order_total: "您的礼品卡和积分已经支付订单总额，无需其他付款方式。",
    digit_only: "请只输入数字",
    please_selected_belows: "请完成所有选择：",
    please_select_location_from_the_list: "请从目录中选择地址。",
    alert_password_reset_success: "您的登录密码已经更改成功，您可以启用新密码登录。",
    alert_email_not_found: "此邮件地址尚未注册",
    alert_password_reset_email_send: "重置密码邮件已经发送",
    x_is_required: "是必填项",
    location_permission_is_blocked_service_may_not_perform_correctly: "位置权限受限，此服务可能会受到影响 ",
    x_missing: "请输入 {x} ",
    x_invalid: "无效 {x}",
    x_select: "请选择支 {x}",
    do_you_want_to_receive_notification: "您希望收到推送通知吗?",
    gift_cards_points_alert: "兑换积分时不能再使用礼品卡; 要使用礼品卡, 请先移除兑换的积分。",
    points_gift_cards_alert_message: "使用礼品卡时就不能再兑换积分; 要兑换积分，请先移除礼品卡。",
    not_found_description: "抱歉！找不到页面",
    not_found_detail_description: "很抱歉，您要查找的页面不存在，已被删除，名称已更改或暂时不可用",
    back_to_home: "返回首页",
    braintree_credit_card_invalid_message: "Something went wrong. Check your card details and try again.",
    please_input_valid_postal_code: "请输入有效的邮编.",
    please_input_valid_x: "请输入有效的{x}",
    too_many_card_attempt: "提供的卡被拒绝次数过多；请尝试使用另一张卡付款。",
    too_many_checkout_attempt: "超出最大重试限制。请稍后再试。",
    payment_declined: "支付失败",
    credit_card_declined: "请检查信用卡信息以确保输入正确，然后重试。如果这样不能解决问题，请致电您的发卡银行解决。",
    no_giftcard: "您没有有效的礼品卡",
    giftcard_insuffienct_fund: "您的礼品卡没有足够的余额来支付",
    invalid_request_body: "无效的请求",
    pay_order: "订单支付",
    expiry_date: "有效期",
    postal_code: "邮编",
    postal_code_zipcd: "邮编",
    country: "國家",
    last_four: "尾数为",
    are_you_sure_delete_card: "你确定要删除这张信用卡吗？",
    visa: "Visa",
    master_card: "Master Card",
    american_express: "American Express",
    discover: "Discover",
    jcb: "JCB",
    maestro: "Maestro",
    credit_card_description: "Visa, Master Card, American Express",
    confirm_pay: "确认支付",
    unsuccessful_payment: "付款失败。",
    reset_password_title: "重设密码",
    phone_placeholder: "手机号",
    new_password: "新密码",
    password_reset_continue: "重置",
    reset_by_text: "通过短信重设",
    reset_by_email: "通过电子邮件",
    alert_vc_expired: "验证码已经失效",
    email_verification: "邮件验证",
    verification_success: "您的账户已经验证!",
    verify_account: "您的账户 {email}，已经验证。",
    verification_fail: "邮件验证失败",
    verify_error: "发生错误，请通过重新发送验证电子邮件重试。{email}",
    view_account: "查看账户",
    link_expired: "无法验证电子邮件地址",
    link_expired_message: "验证链接已过期或已被使用。请再试一次。{email}",
    email_already_verified: "您的帐户已经验证。",
    resend_verification_email: "重新发送验证电子邮件",
    recently_viewed: "最近浏览",
    by_phone: "通过手机短信",
    by_email: "通过电子邮件",
    by_password: "提供原始密码更改",
    op_incorrect: "提交的原始密码不正确。",
    password_change_success: "密码已成功更改.",
    password_request_sent: "已发送更改密码请求",
    social_binding: "Social Binding",
    linked: "Linked",
    or: "或者",
    connect_with_facebook: "用Facebook登录",
    connect_with_twitter: "用Twitter登录",
    connect_with_google: "用Google登录",
    connect_with_wechat: "用微信登录",
    connect_with_apple: "用Apple ID登录",
    facebook: "Facebook",
    twitter: "Twitter",
    linkedin: "LinkedIn",
    whatsapp: "WhatsApp",
    reddit: "Reddit",
    vk: "ВКонтакте",
    pinterest: "Pinterest",
    kakao: "카카오스토리",
    tumblr: "Tumblr",
    blogger: "Blooger",
    skyrock: "Skyrock",
    mix: "Mix",
    goo: "Goo",
    OK: "Одноклассники",
    dont_have_account: "没有账户",
    vouchers: "优惠券",
    gift_card: "礼品卡",
    bookmarks: "收藏",
    bookmark_added: "已添加书签",
    bookmark_deleted: "已删除书签",
    account_info: "帐户信息",
    my_points: "我的积分",
    my_giftcards: "我的礼品卡",
    my_bookmarks: "收藏",
    my_messages: "我的消息",
    my_orders: "我的订单",
    avatar: "头像",
    my_address: "地址簿",
    account_binding: "帐号绑定",
    connect: "绑定",
    safety_settings: "安全设置",
    login_password: "登陆密码",
    modify: "修改",
    delete: "删除",
    first_name: "名字",
    last_name: "姓氏",
    phone_number: "手机号",
    address: "地址",
    pick_place_on_map: "在地图上选择地址",
    done: "完成",
    enter_address_manually: "手动增加地址",
    use_name_account: "使用账户中的名字",
    new_address: "新增地址",
    buzz: "门铃",
    unit: "单元",
    street: "街道",
    city: "城市",
    region: "省份",
    cntry: "国家",
    zipcd: "邮编",
    postalcd: "邮编",
    required_text: "*表示必填项",
    map_search: "地图搜索",
    accept: "接受",
    invalid_last_name: "请提供姓！",
    invalid_credit_card_number: "请提供卡号!",
    invalid_credit_card_cvv: "请提供安全码",
    invalid_cntry: "无效的国家输入",
    Invalid_zipcd: "无效的邮政编码",
    street_missing: "请提供街道!",
    city_missing: "请提供城市!",
    region_missing: "请提供省份!",
    cntry_missing: "请提供国家!",
    zipcd_missing: "请提供邮编!",
    billing_address: "账单地址",
    confirm_delete_address: "确认删除地址",
    delete_address_message: "确认删除此地址？",
    address_edit: "修改地址",
    default: "默认",
    set_as_default: "设为默认",
    nick_name: "昵称",
    update_profile_success: "您的用户资料已经成功更改",
    update_profile_failed: "抱歉，更改用户资料失败，请重试。",
    last_name_missing: "姓氏缺失",
    nick_name_missing: "昵称缺失",
    update: "更新",
    user_info: "用户信息",
    new_phone: "新号码",
    new_email: "新邮件",
    alert_phone_updated: "您的电话号码已成功更新！",
    alert_missing_fields: "抱歉，缺少输入字段。",
    alert_forbidden_action: "抱歉，此操作被禁止。",
    alert_email_updated: "更新电子邮件地址成功",
    alert_forbidden_email: "抱歉，此操作被禁止。",
    alert_email_exists_sign_up: "您输入的电子邮件已被另一个帐户使用。请登录该帐户或使用其他电子邮件。",
    alert_email_exists_update: "您输入的电子邮件已被另一个帐户使用。请使用其他电子邮件。",
    wallet: "钱包",
    confirm_delete_credit_card: "确认删除信用卡",
    confirm_delete_credit_card_message: "你确定要删除这张信用卡吗？",
    add_credit_card_title: "增加信用卡",
    add_credit_card: "增加信用卡",
    save_credit_card: "保存信用卡",
    card_number: "卡号",
    name_on_card: "持卡人",
    cvv: "安全码",
    set_payment_password: "设置支付密码",
    update_password: "更改密码",
    old_password: "旧密码",
    confirm_new_password: "确认新密码",
    password_not_detected: "旧密码不符，请修改后重试或重置密码。",
    server_error: "抱歉，系统异常。请稍后再试。",
    unmatched_passwords: "新密码和确认新密码不一样，请修改并重试。",
    social_bind_not_allowed: "您不可以在微信浏览器里绑定或登录除了微信以外的社区账户，请使用别的浏览器打开此页面",
    social_bind_success: "您的帐户已成功与您的{socialType}帐户相关联。 您现在可以使用{socialType}帐户登录",
    social_bind_failed: "链接{socialType}帐户时出错。请重试。",
    connected: "已绑定",
    confirm_unbind_social_account: "您确定要取消绑定{socialType}帐户吗？",
    unbind_social_success: "您的{socialType}帐户已被解除绑定。",
    unbind_social_error: "抱歉，我们目前无法取消绑定您的{socialType}帐户。请稍后再试。",
    create_new_vault_password: "请设置一个支付密码",
    confirm_vault_password: "请确认密码",
    input_vault_password: "请输入支付密码",
    vault_password: "支付密码",
    password_instructions: "密码需要4-6位数字",
    password_incorrect: "密码错误",
    add_address_invalid_address: "地址无效。请试着在地图上挑选地址。",
    country_not_exists: "国家无效。请试着在地图上挑选地址。",
    map_select_address: "请填写搜索栏并选择建议的地址。",
    error_current_location: "获取当前位置时出错，请在搜索栏中输入您的地址。",
    map_address_accept_error: "谷歌地图错误。请尝试其它地址或手动输入地址。",
    invalid_name: "请填写信用卡上的名字。",
    invalid_cc_info: "请填写持卡人的姓名。",
    invalid_expiry_date: "信用卡有效期无效，请修改后再试。",
    alert_password_min_length: "密码必须至少为6个字符",
    alert_guest_account:
        "您当前是以匿名身份登录的；填写您的姓名，电子邮件/电话号码，即可成为正常客户，日后可享受个性化服务或商家优惠。您想继续吗？",
    alert_missing_account_info: "请填写缺失的信息：{missingInfo}",
    address_amount_exceeded: "超过地址本的数量限制。您最多只能保存10个地址。",
    i_will_do_it_later: "以后再做",
    finish_later: "稍后完成",
    skip: "跳过",
    change_by_email: "通过电子邮件",
    change_by_text_message: "通过手机短信",
    change_by_existing_password: "通过电子邮件",
    set_password: "设置密码",
    change_password: "更改密码",
    code: "验证码",
    request_pass_reset: "请求重设密码",
    confirm_password: "确认密码",
    set_a_password: "设置密码",
    set_a_password_caption: "请设置密码，以便下次更快地登录",
    set_a_new_password: "设置新密码",
    set_a_new_password_caption: "忘记密码了吗？ 设置一个新的，以便下次更快登录。",
    update_password_success: "更新密码成功",
    update_password_fail: "更新密码失败，请稍后再试",
    birthday: "出生日期",
    gender: "性别",
    not_provided: "未提供",
    currency: "货币",
    male: "男",
    female: "女",
    none: "无",
    year: "年",
    month: "月",
    day: "日",
    cannot_update_profile: "无法更新个人资料。",
    updated_profile: "已更新个人资料。",
    giftcards: "礼品卡",
    gift_card_details: "礼品卡详情",
    all: "全部",
    active: "有效的",
    active_giftcard: "可用的",
    inactive_giftcard: "已用完",
    used: "已使用",
    store_name: "商店名称",
    balance: "余额",
    book_value: "面值",
    purchase_date: "购买日期",
    status: "状态",
    reassign: "转送",
    reassign_giftcard: "转送礼品卡",
    receivers_email_phone: "接受人的邮件或者电话",
    giftcard_reassign_success: "礼品卡已成功转送给{receiver}",
    giftcard_reassign_missing_receiver: "请输入接受人的信息",
    giftcard_reassign_missing_info: "您必须有一个有效的接受者才能发送礼品卡。",
    giftcard_reassign_failed: "此电话号码不属于任何注册用户，请尝试其他电话号码",
    giftcard_reassign_failed_phone: "此电话号码不属于任何注册用户，请尝试其他电话号码",
    giftcard_reassign_failed_email: "此电子邮件不属于任何注册用户，请尝试另一个电子邮件.",
    no_giftcards: "您沒有任何礼品卡",
    no_active_giftcards_head: "没有有效礼品卡",
    no_active_giftcards: "你沒有有效的禮品卡",
    no_used_giftcards_head: "没有已用礼品卡",
    no_used_giftcards: "您还没有已用的礼品卡",
    invalid_phone: "电话号码无效，请确保电话号码长度正确",
    giftcard_reassign_tip: "礼品卡转送允许您将购买的礼品卡送给其他注册用户，可以根据此用户的邮件或者电话来发送",
    gift_card_purchased: "购买礼品卡",
    paid_with_gift_card: "用礼品卡支付",
    refunded: "退款",
    transferred_to: "来自: {contact} 的赠送",
    received_from: "转送给: {contact}",
    invalid_phone_number: "无效的电话号码!",
    comment: "备注",
    transactions: "交易",
    details: "详细",
    date: "日期",
    balance_change: "积分变化",
    points_balance: "积分总额",
    no_points: "您尚无奖励积分，现在下单攒积分.",
    no_points_head: "您还没有积分",
    view_stores: "浏览商家",
    last_updated: "最近更新: {time}",
    point_balance: "余额: {balance}",
    point_store_name: "商家名称: {name}",
    points_received: "获得积分",
    store_closed_not_allow_order: "商铺已打烊，目前不接受新订单。",
    no_orders: "您目前还没有订单",
    no_past_orders: "没有以往订单",
    no_upcoming_orders: "没有未完成的订单",
    shop_now: "现在购物",
    pickup_method: "自取时间",
    delivery_method: "配送时间",
    eatin_method: "就绪时间",
    instantCheckout_method: "即刻结帐",
    items: "件商品",
    ongoing: "正在进行",
    history: "历史订单",
    upcoming: "进行中的",
    past_orders: "历史订单",
    buzz_code: "门铃",
    make_default: "设为默认",
    add_address: "新增地址",
    add_a_address: "添加地址",
    are_you_sure_delete_address: "确认删除此地址？",
    select_address: "选择礼地址",
    search_address: "搜索地址",
    current_location: "当前地址",
    use_name_on_account: "使用账户姓名",
    confirm_address: "确认地址",
    location_user_denied: "定位服务已禁用，请启用定位服务",
    geolocation_not_supported: "不支持当前位置服务，请在地址栏中搜索地址",
    location_general_error: "无法获取您的当前位置，请稍后再试",
    self_checkout: "快速支付",
    amount: "金额",
    contact_merchant: "联系商家",
    powered_by_goopter: "由Goopter提供技术支持",
    pay: "支付",
    "self-checkout-disabled": "此商家不能用即刻支付。",
    surcharge_for: "订单#{oid}的附加费",
    phone_number_verification: "电话号码验证",
    please_verify_number: "请验证您的号码",
    verification_code_sent_message: "4位数验证码的短信刚刚发送到 {phoneNumber}",
    enter_code: "输入验证码",
    try_different_number: "尝试不同的号码",
    number_verified_message: "您的号码已通过验证！",
    continue_checkout_message: "请继续完成订单",
    get_vc_descprtion_text: "我们会向您的手机发送验证码",
    payment_success_title: "支付成功",
    payment_success_text: "您的付款已收到。下面将返回到订单页面。",
    go_to_order: "前往该订单",
    qr_scanner: "扫码下单",
    camera_instructor_title: "需要使用摄像头",
    camera_instructor_description_dine_in: "接下来将打开您的相机。请把相机瞄准二维码以继续您的订单。",
    camera_instructor_button_text_dine_in: "扫码下单",
    camera_error_title_order: "相机访问被拒绝",
    camera_error_description_order: "请允许摄像头扫描二维码以继续下单",
    camera_error_button_text_order: "启用摄像头",
    error_invalid_code_dine_in: "此二维码不是用来本商家的堂食点餐用途，请扫描正确的二维码。",
    error_invalid_code_in_store: "此二维码不是用来本商家的堂食点餐用途，请扫描正确的二维码。",
    parking_lot: "停车位号",
    kiosk: "自助终端号",
    require_scan_table_input: "{type} {indicator}",
    tap_to_switch_table: "点按相机切换{type}",
    scan_again: "再试试",
    scanner_title: "将方框对准二维码/条码即可自动扫描",
    orders_today: "今日订单",
    orders_total: "订单总数",
    points_today: "今日推荐积分",
    points_total: "总推荐积分",
    check_how_many_reward_points_from_referrals: "查看您从推荐人那里获得了多少奖励积分",
    no_referrals_message: "您还没有任何推荐。开始推荐赚取奖励积分以在您喜欢的商家消费！",
    order_id: "单号",
    no_referral_orders_found: "未找到推荐订单",
    from: "从",
    pts: "积分",
    sort: "排序",
    filter: "筛选",
    order_items: "已购商品",
    add_to_cart_success: "产品已成功加入购物车",
    add_back: "加回",
    to: "到",
    reward_points_extra_text: "订单完成后，您的帐户积分余额将会更新。",
    gift_cards: "礼品卡",
    with_option: "包含选项",
    qrcode: "二维码",
    email_required: "请提供邮件地址",
    password_required: "密码是必需的",
    phone_required: "请提供电话号码",
    vc_required: "需要验证码",
    please_input_at_least_two_characters: "请输入至少两个字符",
    google: "Google",
    wechat: "WeChat",
    apple: "Apple",
    phone_num: "电话号码",
    change_address: "更改地址",
    inactive: "禁用",
    purchase_a_giftcard: "购买礼品卡",
    self_checkout_disabled: "此商家不提供快速支付",
    quick_pay_hint_1: "快速支付让您随时支付您的订单或欠款！",
    quick_pay_hint_2: "只需输入金额并在备注中提供有关此付款的任何信息。",
    got_it: "知道了。",
    camera_error_title: "无法访问相机",
    camera_error_description_dine_in: "请允许相机访问扫描二维码进行订购。",
    camera_error_button_text: "启用相机",
    msg_country_update: "国家更新成功。",
    msg_currency_update: "货币更新成功。",
    msg_birthday_update: "生日更新成功。",
    msg_email_update: "电子邮件更新成功。",
    msg_gender_update: "性别更新成功。",
    msg_name_update: "姓名更新成功。",
    msg_nickname_update: "昵称更新成功。",
    no_thanks: "不用, 谢谢",
    verify: "验证",
    number_already_exist:
        "此号码已在另一个帐户中注册。如果您想使用此号码下订单，请签退后使用该号码登录。或者您可以使用另一个电话号码。",
    review_recommend_store: "您有多大可能向朋友或家人推荐 {storeName}？",
    example_review_score: "麻烦请告知您为什么选择评分: {score}的原因",
    change_score: "更改评分",
    not_likely: "不太愿意",
    very_likely: "非常愿意",
    review_thank_you_recieve_coupon: "谢谢，您将在大约1分钟内通过短信收到一张优惠券。",
    review_share_your_heart: "麻烦了，可以把您对我们的热爱分享下吗？",
    review_copy_comment: "1. 复制你写的评论。",
    and_share_your_review: "2. 分享你的评论。",
    the_comment_is_copied_to_clipboard: "评语已经复制到剪贴板",
    thank_you: "非常感谢 !",
    review_thank_you_note: "如果你没有收到优惠券短信，请回复 \"my-coupon\" 查看你的优惠券，谢谢!",
    share_to_google: "分享到 Google",
    powered_by: "由 Goopter 提供技术支持",
    quick_pay_self_serve_order_hins: "我有从触摸屏自助下单或扫码下单来的未支付订单。",
    quick_pay_staff_order_hins: "工作人员帮我下的订单。",
    quick_pay_order_type_self_serve: "自助订单全额付款",
    quick_pay_order_type_additional_payment: "自助服务订单的部分或额外付款",
    quick_pay_order_type_staff: "员工辅助下单的订单",
    quick_pay_option_title: "您是否要为已有订单付款？",
    quick_pay_order_type_no_order: "我没有订单号",
    add_payment_note: "添加付款说明",
    select: "请选择",
    leave_quick_pay_confirm_msg: "你确定你要离开快速支付吗？",
    staff_assisted_order_number: "工作人员协助的订单编号",
    please_confirm: "请确认",
    self_serve_order_number: "自助服务订单号",
    self_serve_order_number_input_placeholder: "请输入自助服务订单号",
    staff_assisted_order_number_input_placeholder: "请输入员工辅助订单号",
    invalid_order_id: "订单号无效，请重试。",
    quick_pay_order_type_no_order_placeholder: "没有订单号与此付款相关联",
    quick_pay_order_id: "为{orderType} 订单号: {orderId}",
    quick_pay_confirm_msg: "您确定要为以下订单付款吗",
    quick_pay_confirm_msg_2: " ",
    amount_due: "应付金额",
    quick_pay_confirm_hin: "如果您的订单号相同但应付金额不同，请选择",
    quick_pay_confirm_hin_2: "选项。",
    staff_assisted: "员工辅助下单",
    pls_input_number: "请输入数字",
    back: "返回",
    okay: "确认",
    refunded_details: "退款详情",
    refund_reason: "退款理由",
    deactivate_account: "账户停用与删除",
    msg_deactivation: "您的账户将会被永久删除。在您删除您的 Goopter 账户之后，您将无法使用当前账户登录或检索任何以前的订单或地址记录",
    account_deactivated: "账户已停用并删除",

    //new modified
    paypal_complete_payments:"Credit Card",
    paypal_complete_payments_credit_card:"Credit Card",
    paypal_complete_payments_apple_pay:"Apple Pay",
    paypal_complete_payments_google_pay:"Google Pay",
    paypal_complete_payments_paypal:"PayPal",
};

export default exports;
