import _ from "lodash";

export default class Store {
    constructor(store) {
        store = Object.assign(
            {},
            {
                adr: {},
                cids: [1],
                imgs: [],
                hours: "-1",
                kflg: "",
                aflg: "",
                sflg: "",
                pflg: "",
                op_status: {},
            },
            store
        );
        this.initStoreData(store);
    }

    initStoreData(storeDetail = {}) {
        this.data = {
            ...storeDetail,
            cids: storeDetail["cids"],
            c_id: storeDetail["cids"][0] ? storeDetail["cids"][0] : 1,
            c_id2: storeDetail["cids"][1] ? storeDetail["cids"][1] : null,
            group_id: storeDetail["gid"],
            name: storeDetail["nm"],
            meta: storeDetail["mt"],
            keywords: storeDetail["kws"],
            owner: storeDetail["owner"],
            logo: storeDetail["logo"] ? storeDetail["logo"] : "",

            // address
            adr: storeDetail["adr"],
            unit: storeDetail["adr"]["unit"],
            address: storeDetail["adr"]["street"],
            city: storeDetail["adr"]["city"],
            province: storeDetail["adr"]["region"],
            zip_code: storeDetail["adr"]["zipcd"],
            country: storeDetail["adr"]["cntry"],
            lat: storeDetail["adr"]["lat"],
            lon: storeDetail["adr"]["lon"],

            review_level: storeDetail["rnk"],
            sold_count: storeDetail["scnt"],
            cost: storeDetail["clv"],
            menu:
                (storeDetail["cids"][0] === 1 || storeDetail["cids"][1] === 1) &&
                    storeDetail["menu"] &&
                    storeDetail["menu"].length
                    ? storeDetail["menu"]
                    : null,
            menu_ids:
                (storeDetail["cids"][0] === 1 || storeDetail["cids"][1] === 1) &&
                    storeDetail["menu"] &&
                    storeDetail["menu"].length
                    ? storeDetail["menu"].map((menuItem) => {
                        return menuItem?.id;
                    })
                    : null,
            // 'display_format': storeDetail['df'] ? storeDetail['df'] : null,

            //store_detail area
            lan: storeDetail["lan"], // current used language
            lans: storeDetail["lans"],
            currency: storeDetail["currency"],
            rate: storeDetail["rate"],
            phone: storeDetail["phone"],
            // 'hours': storeDetail['hours'],
            // 'delivery_hours': storeDetail['dh'] ? storeDetail['dh'] : storeDetail['hours'],
            description: storeDetail["desc"],
            himg: storeDetail["himg"],
            mimg: storeDetail["mimg"],
            images: storeDetail["imgs"],
            ratings: storeDetail["ratings"],

            // landing page
            landing_page_data: storeDetail["landing_page"] ? storeDetail["landing_page"] : "",

            //open_status area
            is_open: storeDetail["op_status"]["is_open"],
            can_deliver: storeDetail["op_status"]["can_deliver"],
            allow_order: storeDetail["op_status"]["allow_order"],
            can_preorder: storeDetail["op_status"]["can_pre_order"],
            current_hours: storeDetail["op_status"]["current_hours"],
            dlvy_hours: storeDetail["op_status"]["dlvy_hours"],
            weekly_hours: storeDetail["op_status"]["weekly_hours"],
            weekly_dlvy_hours: storeDetail["op_status"]["weekly_dlvy_hours"],

            timezone: storeDetail["tz"],
            website_url: storeDetail["url"],
            url_key: storeDetail["url_key"],
            category_count: storeDetail["ccnt"],
            product_count: storeDetail["pcnt"],

            // kflg area (Store key flags)
            v2p: storeDetail?.["kflg"]?.[0],
            online_order: storeDetail?.["kflg"]?.[1],
            scan_qr_dine_in: storeDetail?.["kflg"]?.[2],
            require_table_no: storeDetail?.["kflg"]?.[3],
            allow_waitlist: storeDetail?.["kflg"]?.[4],
            waiter_service: storeDetail?.["kflg"]?.[5],
            reservation: storeDetail?.["kflg"]?.[6],
            preorder_threshold: storeDetail?.["kflg"]?.[7], //(-1: allow any time, 0: does not allow pre-order; x: allow pre-order x minutes before store open)
            stop_order_before_close: storeDetail?.["kflg"]?.[8], //(default: 0; x - stop order x minutes before store closed)
            upsell: storeDetail?.["kflg"]?.[9],
            delivery_fee: storeDetail?.["kflg"]?.[10],
            flat_fee_delivery_distance: storeDetail?.["kflg"]?.[11],
            fee_per_km: storeDetail?.["kflg"]?.[12],
            time_interval: storeDetail?.["kflg"]?.[13], //delivery_delta
            max_delivery_distance: storeDetail?.["kflg"]?.[14],
            min_delivery_amount: storeDetail?.["kflg"]?.[15],
            min_pickup_amount: storeDetail?.["kflg"]?.[24],
            show_placeholder_image: storeDetail?.["kflg"]?.[16],
            display_format: storeDetail?.["kflg"]?.[17],
            pdtl_display: storeDetail?.["kflg"]?.[18],
            pagination: storeDetail?.["kflg"]?.[19],
            use_landing_page: storeDetail?.["kflg"]?.[20],
            p2v: storeDetail?.["kflg"][21],
            self_checkout: storeDetail?.["kflg"]?.[22],
            require_billing_address: storeDetail?.["kflg"]?.[23],
            tab_display_style: this.storeTabDisplayStyles(storeDetail),
            allow_multiple_pickup: storeDetail?.["kflg"]?.[28],
            allow_multiple_delivery: storeDetail?.["kflg"]?.[29],
            enabled_third_party_delivery: storeDetail?.["kflg"]?.[54],

            // aflg area (Store additional flags)
            tv: storeDetail?.["aflg"]?.[0],
            free_wifi: storeDetail?.["aflg"]?.[1],
            has_alcohol: storeDetail?.["aflg"]?.[2],
            environment: storeDetail?.["aflg"]?.[3],
            attire: storeDetail?.["aflg"]?.[4], //(1-casual, 2-business casual, 3-smart casual, 4-business, 5-semi formal)
            noise_level: storeDetail?.["aflg"]?.[5], //(-1: none, 0: quiet, 1: average, 2: loud, 3: very loud)
            outdoor_seat: storeDetail?.["aflg"]?.[6],
            kids_friendly: storeDetail?.["aflg"]?.[7],
            group_friendly: storeDetail?.["aflg"]?.[8],
            business_friendly: storeDetail?.["aflg"]?.[9],
            parking: _.padStart(storeDetail?.["aflg"]?.[10], 3, "0"), //(3 digit binary number 1st digit: Street Parking, 2nd digit: Free parking, 3rd digit: Paid parking ie. 010)
            bike_parking: storeDetail?.["aflg"]?.[11],

            // sflg area (Shipping Flag)
            delivery: storeDetail?.["sflg"]?.[0],
            eatin: storeDetail?.["sflg"]?.[1],
            takeout: storeDetail?.["sflg"]?.[2],

            // pflg area (Payment Method Flag)
            accept_creditcard: storeDetail?.["pflg"]?.[0],
            accept_cash: storeDetail?.["pflg"]?.[1],
            accept_points: storeDetail?.["pflg"]?.[2],
            accept_paylater: storeDetail?.["pflg"]?.[3],
            accept_wechat: storeDetail?.["pflg"]?.[4],
            accept_alipay: storeDetail?.["pflg"]?.[5],
            accept_express: storeDetail?.["pflg"]?.[6], // paypal
            accept_stripe: storeDetail?.["pflg"]?.[7],

            // has_online_payment: payments that pay immediately
            has_online_payment:
                storeDetail?.["pflg"]?.[0] ||
                storeDetail?.["pflg"]?.[4] ||
                storeDetail?.["pflg"]?.[5] ||
                storeDetail?.["pflg"]?.[6] ||
                storeDetail?.["pflg"]?.[7],

            // has_payment: has any type of payment
            has_payment:
                storeDetail?.["pflg"]?.[0] ||
                storeDetail?.["pflg"]?.[1] ||
                storeDetail?.["pflg"]?.[2] ||
                storeDetail?.["pflg"]?.[3] ||
                storeDetail?.["pflg"]?.[4] ||
                storeDetail?.["pflg"]?.[5] ||
                storeDetail?.["pflg"]?.[6] ||
                storeDetail?.["pflg"]?.[7],
            // other store_detail area
            discounts: storeDetail["discount"],
            announcements: storeDetail["pa"],
            meta_desc: storeDetail["meta_desc"],
            coupons: storeDetail["coupon"],

            // shipping settings area
            member: storeDetail["plan_id"],
            shipping: storeDetail["shipping"] ? storeDetail["shipping"] : null,
            branches: storeDetail["b_lst"],

            time_setting: storeDetail["time_setting"],

            menus: storeDetail["menus"],
            allow_customer_referrals: Number(storeDetail?.sflg?.[30]) === 1,
            allow_order_referrals: Number(storeDetail?.sflg?.[31]) === 1,
            allow_referrals:
                Number(storeDetail?.kflg?.[30]) * Number(storeDetail?.kflg?.[33]) +
                Number(storeDetail?.kflg?.[31]) * Number(storeDetail?.kflg?.[34]) >
                0,

            delivery_prep_time: storeDetail?.kflg?.[13],
            pickup_prep_time: storeDetail?.kflg?.[39],
            publicNotice: storeDetail["pn"],
            showQtyLeft: Number(storeDetail?.kflg?.[41]),
        };
    }

    storeTabDisplayStyles = (storeDetail) => {
        const menus = Array.isArray(_.get(storeDetail, "menus")) ? _.get(storeDetail, "menus") : [];
        const tabStyle = _.get(storeDetail, "kflg.26");
        return menus.length && tabStyle === 0 ? 4 : tabStyle;
    };

    storeHours(hours) {
        let hourArray = hours.split(";");
        if (hours === "-1") {
            //always open/delivery
            return hours;
        }

        if (hourArray.length !== 7) {
            throw new Error("storeHours hourArray length is not 7");
        }

        let finalHourObject = {};
        hourArray.forEach(function (currentDay) {
            finalHourObject[currentDay.slice(0, 3)] = currentDay.slice(4).split(",");
        });
        return finalHourObject;
    }

    getHoursToday(hours) {
        let hoursArray = this.storeHours(hours);

        if (hours === "-1") {
            return hours;
        }

        let weekday = new Date().getDay();
        return Object.values(hoursArray)[weekday - 1].join(",");
    }
}
