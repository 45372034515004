import { createSlice } from "@reduxjs/toolkit";
import config from "config";
import _ from "lodash";
import Store from "utils/model/store";
import helper, { getPrice, isThirdPartyLocalDeliveryService, isDeliveryEnabled } from "../../utils/helper";
import { getUserPhone } from "../account-page/helper";
import { ASAP } from "../app/constants";
import { pushScanUrl, SCAN_DIRECTS } from "../qr-scanner/helper";
import { push } from "containers/app/history"
import {
    allowPayment,
    getAgentPayment,
    getMaxDeliveryDistance,
    getStoreDistanceUnit,
    requireScanForDineIn,
} from "../store-page/helper";
import pageHelper, {
    getInstantCheckoutParams,
    getRequireUtentsilDefaultValue,
    setBestTimeSlot,
    setBestTimeSlotMultipleLocations,
} from "./helper";
const initialCustomerData = {
    userId: "",
    customerRole: "",
    firstName: "",
    lastName: "",
    phone: "",
    isPasswordSet: false,
    comment: "",
    defaultAddress: null,
    billingAddress: null,
    card: null,
    inputCard: null,
    appliedCoupon: "",
    couponButtonClick: false,
    paymentMethod: null,
    activePaymentKey: 0,
    shippingMethod: null,
    shippingTypes: [],
    selectedShippingType: "",
    gsid: null,
    allowedPeriods: {},
    deliveryDisplayDate: "",
    partySize: 1,
    validPhone: true,
    tipsButtonSelected: false,
    qid: null,
    confirmCheckoutSuccess: false,
    customer: {}, //copy of the original customer data,
    nonce: null,
    requireUtensil: true,
};

const initialPointsData = {
    pointsBalance: 0,
    pointsUsed: 0,
    pointsDialogUsed: 0,
};

const initialGiftCardData = {
    giftCards: [],
    giftCardsPagination: {
        page: 1,
        hasMore: false,
    },
    selectedGiftCard: null,
    giftCardsNum: 0,
};

const initialCartData = {
    carts: {},
    shippingMethods: {},
    products: {},
    groupPurchaseIds: {},
    availableShippingMethod: {},
};

const initialModalData = {
    displayModal: false,
    displayModalTitle: "",
    displayModalContent: "",
    displayModalKeywords: {},
    displayModalHandlingType: "",
    displayModalContentType: "",
    displayModalName: "",
    displaySubModalName: "",
    displayOrderTypePicker: false,
    displayDeliveryTimePicker: false,
    showTypeMinDialog: false,
    showTypeMinDialogValue: 0,
    showTypeMinDialogType: "",
    switchingToEatIn: false,
    confirmSwitchToEatIn: false,
    showPickupLocationDrawer: false,
    displayErrorMinAmt: 0,
    displayErrorDeliveryMinTips: true,
    displayModalFormatKey: "",
};

const initialState = {
    ...initialCustomerData,
    ...initialPointsData,
    ...initialGiftCardData,
    ...initialCartData,
    ...initialModalData,
    shippingAddress: {},
    getQuoteLoading: false,
    coupon: "",
    isInitialized: false,
    storeInstance: {},
    priceInfo: {
        grandTotal: 0,
        tax: 0,
        discounts: 0,
        shippingFee: 0,
        couponDiscount: 0,
        storeDiscount: 0,
        tips: 0,
        subTotal: 0,
        tipsPercent: 10,
    },
    displayPriceInfo: {
        totalPaid: 0,
        totalDue: 0,
        giftcardPayment: 0,
        pointsPayment: 0,
    },
    minTips: 0,
    shouldShowRequiredIndicatorText: false,
    discountedUpsellProducts: [],
    openDiscountedUpsellDialog: false,
};

const findCartsIndex = (payload, carts) => {
    return payload.options
        ? carts.findIndex((product) => {
            const isSameProduct = _.get(product, "pid", "") === _.get(payload, "product.pid", "");
            const isSameOptions = _.isEqual(_.get(product, "options", []), _.get(payload, "options", []));
            return isSameProduct && isSameOptions;
        })
        : carts.findIndex((product) => product.pid === payload.product.pid);
};

const removeCheckoutCartItemHelper = (state, payload) => {
    const gid = _.get(payload, "store.group_id", _.get(payload, "store.gid"));
    const store = _.cloneDeep(_.get(state, `storeInstance`, {}));
    const payment = _.cloneDeep(_.get(state, `paymentMethod`, {}));
    const carts = _.get(state, `carts.${gid}`, []);
    let products = _.cloneDeep(_.get(state, `products.${gid}`, []));
    const existingIndex = findCartsIndex(payload, carts);
    if (existingIndex !== -1) {
        carts[existingIndex].qty -= 1;
        if (carts[existingIndex].qty === 0 || payload.removeAll) {
            carts.splice(existingIndex, 1);
            if (!carts.find((item) => item.pid === payload.product.pid)) {
                products = products.filter((product) => product.pid !== payload.product.pid);
            }
        }
    }

    state.carts[gid] = carts;
    state.products[gid] = products;

    if (pageHelper.isCartOnlyHasGiftCards({}, carts)) {
        state.shippingMethods[gid] = "freeShipping";
        if (!allowPayment(store, payment, "freeShipping")) {
            state.paymentMethod = null;
        }
    }
};

const displayStoreOfflineModal = (state) => {
    state.displayModal = true;
    state.displayModalTitle = "warning";
    state.displayModalContent = "store_offline_message";
    state.displayModalHandlingType = "back_to_store";
};

const checkout = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        //to component
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        clearData: (state, { payload }) => {
            state = Object.assign(state, initialState);
        },
        clearSesitiveData: (state, { payload }) => {
            state = Object.assign(state, initialCustomerData);
        },
        updateTips: (state, { payload }) => {
            let { amount, type, isButton } = payload;
            if (type === "flat") {
                state.priceInfo.tips = _.isNaN(amount) ? 0 : amount;
                state.priceInfo.tipsPercent = null;
            } else if (type === "percent") {
                state.priceInfo.tipsPercent = amount;
            }
            if (isButton) {
                state.tipsButtonSelected = true;
            } else {
                state.tipsButtonSelected = false;
            }
            state.tipsConfirm = true;
        },
        updateMinTips: (state, { payload }) => {
            let { grandTotal, tax, shippingFee } = _.cloneDeep(_.get(state, `priceInfo`, {}));
            const { amount, isPercentType, shippingMethod } = payload;
            let tips = 0;
            if (isPercentType) {
                tips = _.round((grandTotal + shippingFee) * (parseFloat(amount) * 0.01), 2);
                state.minTips = tips;
            } else {
                const formattedTips = parseFloat(amount).toFixed(2);
                state.minTips = _.isNaN(formattedTips) ? 0 : parseFloat(formattedTips);
            }
        },
        saveDeliveryTime: (state, { payload }) => {
            const { name, value } = payload;
            if (name === "deliveryTime") {
                state.deliveryTime = value;
                state.deliveryDate = state.deliveryDisplayDate;
            } else if (name === "deliveryDisplayDate") {
                state.deliveryDisplayDate = value;
            } else if (name === "deliveryDate") {
                state.deliveryDisplayDate = value;
                state.deliveryDate = value;
            }
        },
        updateDisplayPrice: (state, { payload }) => {
            let { grandTotal, tax, shippingFee } = _.cloneDeep(_.get(state, `priceInfo`, {}));
            const selectedShippingType = _.cloneDeep(_.get(state, `selectedShippingType`, ""));
            const shippingTypes = _.cloneDeep(_.get(state, `shippingTypes`, []));
            const store = new Store(_.cloneDeep(_.get(state, `storeInstance`, {})));
            const updateReason = _.get(payload, "updateReason");
            let totalPaid = 0,
                pointsPaid = 0,
                giftCardPaid = 0,
                tips = 0,
                orderTotal = 0;

            //update shipping fee
            let method = pageHelper.getSelectedThirdPartyMethod(selectedShippingType, shippingTypes);
            if (!_.isEmpty(method)) {
                shippingFee = _.get(method, "fee", 0);
                state.priceInfo.shippingFee = shippingFee;
            }

            //update tips
            if (state.priceInfo.tipsPercent != null) {
                tips = _.round((grandTotal + shippingFee) * (parseFloat(state.priceInfo.tipsPercent) * 0.01), 2);
                state.priceInfo.tips = tips;
            } else {
                const formattedTips = parseFloat(state.priceInfo.tips).toFixed(2);
                tips = _.isNaN(formattedTips) ? 0 : parseFloat(formattedTips);
            }

            orderTotal = _.round(grandTotal + shippingFee + tips, 2);

            //update points payment
            const p2v = store?.data?.p2v;
            const selectedGiftCard = state.selectedGiftCard;
            if (!_.isEmpty(selectedGiftCard)) {
                //logic added to only allow points or giftcard
                state.pointsUsed = 0;
                state.pointsDialogUsed = 0;
            } else if (orderTotal < pageHelper.MIN_POINTS_AMOUNT) {
                state.pointsUsed = 0;
                state.pointsDialogUsed = 0;
            } else if (p2v > 0 && state.pointsUsed >= 0) {
                const remainingOrderTotal = orderTotal - totalPaid;
                pointsPaid = parseFloat(state.pointsUsed) / parseFloat(p2v);
                const amountDecrease = pointsPaid > remainingOrderTotal;
                const pointsBalance = state.pointsBalance;

                const usingPoints = String(state.paymentMethod) === "4"; //points payment
                const amountIncrease = remainingOrderTotal > pointsPaid;
                const balanceSuffice = pointsBalance > remainingOrderTotal;
                const shouldPayMoreWithPoints = amountIncrease && balanceSuffice && usingPoints;
                const notUpdateByPoints = updateReason !== "pointUsedIsDiff";
                if ((shouldPayMoreWithPoints || amountDecrease) && notUpdateByPoints) {
                    const newPointsUsed = remainingOrderTotal * 100;
                    state.pointsUsed = parseInt(newPointsUsed);
                    pointsPaid = parseFloat(newPointsUsed) / parseFloat(p2v);
                }
                totalPaid += pointsPaid;
            }

            //update giftcard payment
            if (state.pointsUsed) {
                //logic added to only allow either points or giftcard
                state.selectedGiftCard = null;
            } else if (selectedGiftCard && orderTotal > totalPaid) {
                let remainingOrderTotal = orderTotal - totalPaid;
                if (remainingOrderTotal > parseFloat(selectedGiftCard.bal)) {
                    totalPaid += parseFloat(selectedGiftCard.bal);
                    giftCardPaid = selectedGiftCard.bal;
                } else if (remainingOrderTotal <= parseFloat(selectedGiftCard.bal)) {
                    totalPaid += parseFloat(remainingOrderTotal);
                    giftCardPaid = remainingOrderTotal;
                }
            }

            // payment method change if total paid for the whole order total
            const paymentMap = config.PAYMENT_METHOD_NUMERIC_MAPPING;
            if (orderTotal === 0) {
                state.paymentMethod = paymentMap.free; //free
                tips = 0;
            } else if (totalPaid === orderTotal) {
                state.card = initialState.card;
                if (pointsPaid && giftCardPaid) {
                    state.paymentMethod = paymentMap.points_and_giftcard;
                    state.activePaymentKey = null;
                } else if (pointsPaid > 0) {
                    state.paymentMethod = paymentMap.points;
                    state.activePaymentKey = null;
                } else if (giftCardPaid) {
                    state.paymentMethod = paymentMap.giftcard;
                    state.activePaymentKey = null;
                }
            } else if (
                Number(state.paymentMethod) === 5 ||
                Number(state.paymentMethod) === 4 ||
                Number(state.paymentMethod) === 3
            ) {
                state.paymentMethod = state.initialPaymentMethod;
                state.activePaymentKey = state.initialPaymentMethod;
            }

            let totalDue = orderTotal - totalPaid > 0 ? orderTotal - totalPaid : 0;
            let totalBeforeTax = orderTotal - tax - tips - totalPaid > 0 ? orderTotal - tax - tips - totalPaid : 0;

            state.displayPriceInfo.orderTotalCal = orderTotal;
            state.displayPriceInfo.orderTotal = _.round(totalDue, 2);
            state.displayPriceInfo.totalBeforeTax = _.round(totalBeforeTax, 2);
            state.displayPriceInfo.pointsPayment = _.round(pointsPaid, 2);
            state.displayPriceInfo.giftcardPayment = _.round(giftCardPaid, 2);
            state.displayPriceInfo.totalPaid = _.round(totalPaid, 2);
            state.displayPriceInfo.totalDue = _.round(totalDue, 2);
        },
        updateShippingAddress: (state, { payload }) => {
            state.shippingAddress = payload.address;
            state.shouldUpdateAddress = false;
            payload.callBack && payload.callBack();
        },
        updateCheckoutCart: (state, { payload }) => {
            const gid = _.get(payload, "gid", "");
            const shippingMethod = _.get(payload, "shippingMethod", "");
            const tableNumber = _.get(payload, "tableNumber", "");
            const store = _.get(payload, "store");

            state.gid = gid;
            state.carts[gid] = _.get(payload, "cart", "");
            state.shippingMethods[gid] = shippingMethod;
            state.products[gid] = _.get(payload, "products", "");
            state.groupPurchaseIds[gid] = _.get(payload, "groupPurchaseId", "");
            state.availableShippingMethod[gid] = _.get(payload, "availableMethods", []);
            state.backUrl = _.get(payload, "props.match.url", "");
            state.relatedOid = _.get(payload, "relatedOid", "");
            state.taxClass = _.get(payload, "taxClass");
            state.comment = _.get(payload, "comment", "");
            state.paymentMethod = null;
            state.coupon = _.get(payload, "coupon", "");
            state.couponFromStorePage = _.get(payload, "coupon", "") ? true : false;
            state.tableNumber = tableNumber;

            const requireScan = requireScanForDineIn(store);
            const isDineIn = shippingMethod === "eatin";
            if (isDineIn && requireScan && !tableNumber) {
                pushScanUrl({
                    forward: SCAN_DIRECTS.check_out,
                });
            } else {
                push(`/checkout?query=${gid}`);
            }
        },
        addCheckoutCartItem: (state, { payload }) => {
            let carts = _.cloneDeep(_.get(state, `carts.${payload.store.group_id}`, []));
            let products = _.cloneDeep(_.get(state, `products.${payload.store.group_id}`, []));
            let productType = _.get(payload, "product.pt");
            let isSelfInput = productType === 2;
            let price =
                isSelfInput && payload.selfInput ? payload.selfInput : helper.getPrice({ data: payload.product });
            let originalPrice = isSelfInput && payload.selfInput ? payload.selfInput : payload.product.pc;
            const existingIndex = findCartsIndex(payload, carts);
            if (existingIndex !== -1) {
                if (productType === 2) {
                    carts[existingIndex].price = price;
                    carts[existingIndex].originalPrice = originalPrice;
                    carts[existingIndex].qty = _.get(payload, "qty", 1);
                } else {
                    carts[existingIndex].qty += _.get(payload, "qty", 1);
                }
            }
            state.carts[payload.store.group_id] = carts;
            state.products[payload.store.group_id] = products;
        },
        updateCheckoutCartItem: (state, { payload }) => {
            let carts = _.cloneDeep(_.get(state, `carts.${payload.store.group_id}`, []));
            let products = _.cloneDeep(_.get(state, `products.${payload.store.group_id}`, []));
            let productType = _.get(payload, "product.pt");
            let isSelfInput = productType === 2;
            let price =
                isSelfInput && payload.selfInput ? payload.selfInput : helper.getPrice({ data: payload.product });
            let originalPrice = isSelfInput && payload.selfInput ? payload.selfInput : payload.product.pc;
            const existingIndex = payload?.index ?? findCartsIndex(payload, carts);
            if (existingIndex !== -1) {
                if (productType === 2) {
                    carts[existingIndex].price = price;
                    carts[existingIndex].originalPrice = originalPrice;
                    carts[existingIndex].qty = _.get(payload, "qty", 1);
                } else {
                    carts[existingIndex].qty = _.get(payload, "qty", 1);
                    if (payload.options) {
                        carts[existingIndex].options = payload?.options ?? {};
                    }
                }
            } else {
                let options = _.get(payload, "options", {});

                Object.keys(options).forEach((id) => {
                    const option = _.get(payload, "product.opt", []).find((opt) => String(opt?.id) === String(id));
                    const selected = Array.isArray(options?.[id]) ? options?.[id] : [];
                    const subOptons = Array.isArray(option?.opts) ? option?.opts : [];
                    if (!_.isEmpty(option)) {
                        selected.forEach((selectedId) => {
                            const opt = subOptons.find((subOpt) => Number(subOpt.id) === Number(selectedId));
                            price += Number(_.get(opt, "pc")) ?? 0;
                            originalPrice += Number(_.get(opt, "pc")) ?? 0;
                        });
                    }
                });

                let product_name = _.get(payload, "product.nm")
                //Issue 14502 - Prevent adding empty product name to cart due to unknown gid corruption or invalid product
                if (_.isString(product_name) && !_.isEmpty(product_name.trim())) {
                    carts.push({
                        pid: payload?.product?.pid,
                        qty: _.get(payload, "qty", 1),
                        price,
                        originalPrice,
                        productType: payload?.product?.pt,
                        nm: payload?.product?.nm,
                        options: _.get(payload, "options", {}),
                        menu: payload?.selectedMenu,
                    });

                    if (!products.find((product) => product.pid === payload?.product?.pid)) {
                        products.push(payload.product);
                    }
                }
            }
            state.carts[payload.store.group_id] = carts;
            state.products[payload.store.group_id] = products;
        },
        removeCheckoutCartItem: (state, { payload }) => {
            removeCheckoutCartItemHelper(state, payload);
        },
        removeAllCheckoutCartItemsByProductId: (state, { payload }) => {
            const gid = _.get(payload, "store.group_id", _.get(payload, "store.gid"));
            const carts = _.get(state, `carts.${gid}`, []);
            const removalId = _.get(payload, "removed_id");

            let index;
            while (index !== -1) {
                index = carts.findIndex((product) => product.pid === removalId);
                if (index !== -1) {
                    removeCheckoutCartItemHelper(state, payload);
                }
            }
        },
        updateCheckoutCartMethod: (state, { payload }) => {
            const store = _.cloneDeep(_.get(state, `storeInstance`, {}));
            const payment = _.cloneDeep(_.get(state, `paymentMethod`, {}));

            const gid = _.get(store, "gid", "");
            state.shippingMethods[gid] = payload;
            state.displayOrderTypePicker = false;
            if (!allowPayment(store, payment, payload)) {
                state.paymentMethod = null;
            }
        },
        //to service
        getStoreSucess: (state, { payload }) => {
            const storeData = _.get(payload, "storeResponse.records", {});

            //set up initial Data
            state = Object.assign(state, initialGiftCardData);
            state = Object.assign(state, initialModalData);
            state = Object.assign(state, initialPointsData);

            //set up store
            if (!_.isEmpty(storeData)) {
                state.storeInstance = storeData;
                state.gid = storeData.gid;
                state.countryCode = state.storeInstance.country;
                state.requireUtensil = getRequireUtentsilDefaultValue(storeData);
            }
        },
        getUserProfileSuccess: (state, { payload }) => {
            const customerData = _.get(payload, "userProfileResponse.records", {});

            //set up customer data
            if (!_.isEmpty(customerData)) {
                state.userId = customerData["user_id"];
                state.firstName = state.firstName ? state.firstName : customerData["first_name"];
                state.lastName = state.lastName ? state.lastName : customerData["last_name"];
                if (getInstantCheckoutParams()) {
                    state.shippingAddress = {};
                } else if (!_.isEmpty(state.shippingAddress)) {
                    state.shippingAddress = { ...state.shippingAddress };
                } else if (!_.isEmpty(customerData?.defaultAddress)) {
                    state.shippingAddress = customerData?.defaultAddress;
                } else {
                    state.shippingAddress = {};
                }
                state.phone = getUserPhone(customerData) || customerData?.defaultAddress?.tn;
                state.billingAddress = customerData["defaultAddress"];
                state.defaultAddress = customerData["defaultAddress"];
                state.customer = customerData["customer"] ? customerData["customer"] : customerData;
                state.refreshCustomer = false;
            }
        },
        getStoreGiftCardsSuccess: (state, { payload }) => {
            const records = _.get(payload, "giftCardsReponse.records", []);
            if (!_.isEmpty(records)) {
                state.giftCards = records;
            }
        },
        checkoutCartSuccess: (state, { payload }) => {
            //set up initial data
            state = Object.assign(state, initialGiftCardData);
            state = Object.assign(state, initialPointsData);

            const clearLocationInfo = (state) => {
                state.shippingFee = initialState.shippingFee;
                state.shippingAddress = initialState.shippingAddress;
                state.pickupLocationId = initialState.pickupLocationId;
                state.pickupLocations = [];
                state.allowedPeriods = [];

                state.deliveryDisplayDate = "";
                state.deliveryDate = "";
                state.deliveryTime = "";
                return state;
            };
            //hanle payload
            if (payload && payload.response) {
                const { records, message } = _.get(payload, "response.checkoutCartResponse", {});
                const RC = Number(_.get(payload, "response.checkoutCartResponse.RC", 400));
                const { shippingMethod, address, productList, coupon, couponApplied } = _.get(payload, "payload", {});
                const store = _.cloneDeep(_.get(state, `storeInstance`, {}));

                state.shippingMethod = shippingMethod;

                if (records) {
                    const grandTotal = records.g_total ? records.g_total : 0;
                    const tax = records.tax ? records.tax : 0;
                    const discounts = records.dis_ttl ? records.dis_ttl : 0;
                    const couponDiscount = records.cpn_dis ? records.cpn_dis : 0;
                    const storeDiscount = records.store_dis ? records.store_dis : 0;
                    const pointsBalance = records.p_bal ? records.p_bal : 0;
                    const surcharge = records.surcharge || 0;
                    const pickupLocations = Array.isArray(_.get(records, "pickup_locations"))
                        ? _.get(records, "pickup_locations")
                        : [];
                    state.qid = records.qid;
                    state.priceInfo.grandTotal = parseFloat(grandTotal);
                    state.priceInfo.tax = parseFloat(tax);
                    state.priceInfo.discounts = parseFloat(discounts);
                    state.priceInfo.couponDiscount = parseFloat(couponDiscount);
                    state.priceInfo.storeDiscount = parseFloat(storeDiscount);
                    state.priceInfo.surcharge = parseFloat(surcharge);
                    state.pointsBalance = parseFloat(pointsBalance);
                    state.giftCardsNum = records.gc_count || 0;
                    state.priceInfo.subTotal =
                        parseFloat(grandTotal) + parseFloat(Math.abs(discounts)) - parseFloat(tax);
                    state.paymentMethod =
                        grandTotal === 0
                            ? config.PAYMENT_METHOD_NUMERIC_MAPPING.free
                            : getAgentPayment(store, shippingMethod)
                                ? getAgentPayment(store, shippingMethod)
                                : _.get(state, `paymentMethod`, -1);
                    state.priceInfo.tipsPercent =
                        grandTotal === 0 || !pageHelper.getTipsSetting(store).is_enable
                            ? 0
                            : grandTotal > pageHelper.getTipsSetting(store).cut &&
                                pageHelper.getDefaultTips(store, shippingMethod, true) !== -1
                                ? pageHelper.getDefaultTips(store, shippingMethod, true)
                                : null;
                    state.priceInfo.tips =
                        grandTotal === 0 || !pageHelper.getTipsSetting(store).is_enable
                            ? 0
                            : grandTotal <= pageHelper.getTipsSetting(store).cut &&
                                pageHelper.getDefaultTips(store, shippingMethod, false) !== -1
                                ? pageHelper.getDefaultTips(store, shippingMethod, false)
                                : 0;
                    state.tipsButtonSelected = true;
                    state.pickupLocations = pickupLocations;
                    state.customerRole = records?.customer_role;
                    state.tipsConfirm = false;
                    state.displayErrorDeliveryMinTips = isDeliveryEnabled(store) && isThirdPartyLocalDeliveryService(store)
                }

                if (RC === 200) {
                    //set up initial shipping fee
                    if (Number(shippingMethod) === 1) {
                        //1 == delivery
                        // third pary shipping
                        if (!_.isEmpty(records?.shp_mtd)) {
                            if (!_.isEqual(state.shippingTypes, records["shp_mtd"])) {
                                state.shippingTypes = records["shp_mtd"];
                                state.priceInfo.shippingFee = records["shp_mtd"][0]["methods"][0]["fee"];
                                state.selectedShippingType = records["shp_mtd"][0]["methods"][0]["code"];
                            }
                            // regular shipping
                        } else {
                            if (records["shp_fee"]) {
                                state.priceInfo.shippingFee = parseFloat(records.shp_fee);
                            } else {
                                // this should only happen if there are errors to getquote like no address provided or a change of address that is invalid or out of shipping range
                                state.priceInfo.shippingFee = 0;
                            }
                            state.shippingTypes = initialState.shippingTypes;
                            state.selectedShippingType = initialState.selectedShippingType;
                        }
                    } else {
                        state.priceInfo.shippingFee = 0;
                        state.shippingTypes = initialState.shippingTypes;
                        state.selectedShippingType = initialState.selectedShippingType;
                    }

                    // sets best date and time when Multiple location exists and pickup is selected
                    if (
                        "pickup_locations" in records &&
                        Number(shippingMethod) === Number(config.SHIPPING_MAPPING_TO_NUMERIC["pickup"]) &&
                        Object.keys(records["pickup_locations"]).length > 0
                    ) {
                        let pickupLocations = records["pickup_locations"];
                        let times = pickupLocations[0].timeslots;
                        //set up pickup location period
                        if (
                            !state.pickupLocationId ||
                            !state.deliveryDate ||
                            !state.deliveryTime ||
                            !state.allowedPeriods
                        ) {
                            state.pickupLocationId = pickupLocations[0].id;
                            let firstDate = Object.keys(times)[0];
                            let firstDatePeriod = times[firstDate];
                            // settings default delivery/pickup time to first date first time, usually the ASAP field.
                            state.allowedPeriods = times;
                            // set display date to asap or full date of first available period
                            if (firstDate && firstDatePeriod) {
                                state.deliveryDisplayDate = firstDate;
                                state.deliveryDate = firstDate;
                                state.deliveryTime = firstDatePeriod[0];
                            }
                        }
                        // sets state for best date and time when new periods from ctotal API changes
                        setBestTimeSlotMultipleLocations(state, pickupLocations);
                    }

                    // sets state to empty when empty allowed_periods object is returned for pickup or delivery
                    if ("allowed_periods" in records && _.isEmpty(Object.keys(records["allowed_periods"]))) {
                        state.allowedPeriods = records["allowed_periods"];
                    }

                    //set up allowed period
                    if (
                        ((Number(shippingMethod) === Number(config.SHIPPING_MAPPING_TO_NUMERIC["delivery"]) &&
                            address) ||
                            Number(shippingMethod) === Number(config.SHIPPING_MAPPING_TO_NUMERIC["pickup"])) &&
                        records["allowed_periods"] &&
                        Object.keys(records["allowed_periods"]).length > 0
                    ) {
                        // if the delivery date isn't set yet or the allowed periods are changed.
                        if (!state.deliveryDate || !state.allowedPeriods) {
                            let firstDate = Object.keys(records["allowed_periods"])[0];
                            let firstDatePeriod = records["allowed_periods"][firstDate];
                            // settings default delivery/pickup time to first date first time, usually the ASAP field.
                            state.allowedPeriods = records["allowed_periods"];
                            // set display date to asap or full date of first available period
                            if (firstDate && firstDatePeriod) {
                                state.deliveryDisplayDate = firstDate;
                                state.deliveryDate = firstDate;
                                state.deliveryTime = firstDatePeriod[0];
                            }
                        }

                        // if Pickup or Delivery allowed_periods changes then getBestTimeSlot
                        // function is called to select the nearest date and time
                        setBestTimeSlot(state, records["allowed_periods"]);
                    }

                    //coupon applied result
                    if ((couponApplied || state.couponFromStorePage) && coupon) {
                        state = Object.assign(state, initialModalData);
                        if (!records.cpn_dis || Number(records.cpn_dis) === 0) {
                            state.displayModal = true;

                            state.displayModalTitle = "coupon_invalid";
                            state.displayModalContent = "invalid_coupon";
                            state.displayModalKeywords = { couponCode: coupon };
                            state.displayModalHandlingType = "continue";
                            state.coupon = "";
                        } else {
                            state.displayModal = true;
                            state.displayModalTitle = "coupon_valid";
                            state.displayModalContent = "coupon_applied";
                            state.displayModalKeywords = { couponCode: coupon };
                            state.displayModalHandlingType = "continue";
                            state.coupon = "";
                            state.appliedCoupon = coupon;
                        }
                        state.couponFromStorePage = false;
                    }
                } else if (RC === 206) {
                    state = clearLocationInfo(state);
                    state = Object.assign(state, initialModalData);

                    state.displayModal = true;
                    state.displayModalTitle = "distance_exceeded";
                    state.displayModalContent = "exceed_delivery_distance_select";
                    state.displayModalHandlingType = "continue";
                    state.displayModalKeywords = {
                        delivery_distance: _.get(new Store(store), "data.max_delivery_distance", ""),
                        distance_unit: getStoreDistanceUnit(store),
                    };
                } else if (RC === 460) {
                    state.shippingMethod = initialState.shippingMethod;
                    state = clearLocationInfo(state);
                    state = Object.assign(state, initialModalData);

                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "invalid_shipping_method";
                    state.displayModalHandlingType = "back_to_store";
                } else if (RC === 207) {
                    state = clearLocationInfo(state);
                    state = Object.assign(state, initialModalData);

                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "invalid_address";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 400) {
                    const invalidAddress1 = message && message === "The input address is not valid";
                    const invalidAddress2 =
                        message && message.includes("No availabilities found for given coordinates");
                    if (invalidAddress1) {
                        state = clearLocationInfo(state);
                        state = Object.assign(state, initialModalData);

                        state.displayModal = true;
                        state.displayModalTitle = "error";
                        state.displayModalContent = "invalid_address";
                        state.displayModalHandlingType = "continue";
                    } else if (invalidAddress2) {
                        state = clearLocationInfo(state);
                        state = Object.assign(state, initialModalData);

                        state.displayModal = true;
                        state.displayModalTitle = "invalid_delivery_zone_title";
                        state.displayModalContent = "invalid_delivery_zone_content";
                        state.displayModalContentType = "invalid_delivery_zone";
                        state.displayModalHandlingType = "continue";
                    }
                } else if (RC === 405) {
                    displayStoreOfflineModal(state);
                } else if (RC === 485) {
                    // only returned for groupsale chechkouts as of August 13, 2018
                    state = clearLocationInfo(state);
                    state = Object.assign(state, initialModalData);

                    let allowedCountries = state.groupsaleInstance.data["shipping_rules"].map((country) => {
                        return country.country_code;
                    });
                    state.displayModal = true;

                    if (allowedCountries) {
                        state.displayModalTitle = "error";
                        state.displayModalContent = "shipping_country_text";
                        state.displayModalKeywords = {
                            countries: allowedCountries ? allowedCountries.join(", ") : "",
                        };
                        state.displayModalHandlingType = "continue";
                    }
                } else if (RC === 1000) {
                    state = Object.assign(state, initialModalData);
                    const products = Array.isArray(productList) ? productList : [];
                    const oosRecords = records?.oos || [];
                    const { oosItems, oosPids } = helper.getOOSInfo(products, oosRecords);
                    state.displayModal = true;
                    state.displayModalTitle = "warning";
                    state.displayModalHandlingType = "keep_available_items";
                    state.displayModalContentType = "item_oos";
                    state.displayModalContent = "item_oos";
                    state.displayModalAllowCancel = false;
                    state.displayOOSProduct = helper.getOOSOptionKeywords(oosItems);
                    state.displayModalHandlingState = { oosPids, oosItems, oosRecords };
                } else if (RC === 513) {
                    state = Object.assign(state, initialModalData);
                    state.displayModal = true;
                    state.displayModalTitle = "coupon_invalid";
                    state.displayModalContent = "invalid_coupon";
                    state.displayModalKeywords = { couponCode: coupon };
                    state.displayModalHandlingType = "continue";
                    state.coupon = "";
                } else if (RC === 209) {
                    state = Object.assign(state, initialModalData);
                    const { required, given } = _.get(payload, "response.checkoutCartResponse", {});
                    const currency = _.get(store, "currency", "CAD");
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "min_delivery_amt_required";
                    state.displayModalKeywords = {
                        required: helper.formatCurrency(required, currency),
                        gap: helper.formatCurrency(required - given, currency),
                    };
                    state.displayModalHandlingType = "back_to_store";
                    state.displayErrorMinAmt = required;
                } else if (RC === 210) {
                    state = Object.assign(state, initialModalData);
                    state = clearLocationInfo(state);
                    if (!_.isEmpty(payload?.payload?.address)) {
                        state.displayModal = true;
                        state.displayModalTitle = "invalid_delivery_zone_title";
                        state.displayModalContent = "invalid_delivery_zone_content";
                        state.displayModalContentType = "invalid_delivery_zone";
                        state.displayModalHandlingType = "continue";
                    }
                } else if (RC === 211) {
                    state = Object.assign(state, initialModalData);
                    const gid = _.get(payload, "payload.gid");
                    const isEmptyCart = _.isEmpty(state.carts[gid]);
                    state.displayModal = true;
                    state.displayModalTitle = "warning";
                    state.displayModalContent = isEmptyCart
                        ? "discounted_upsell_items_removed_empty_cart_msg"
                        : "discounted_upsell_items_removed_msg";
                    state.displayModalHandlingType = isEmptyCart ? "back_to_store" : "continue";
                    state.displayModalAllowCancel = false;
                } else if (RC === 456) {
                    state = Object.assign(state, initialModalData);
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "insufficient_fund";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 461) {
                    state = Object.assign(state, initialModalData);
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "invalid_payment_info";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 427) {
                    state = Object.assign(state, initialModalData);
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "merchant_not_ready";
                    state.displayModalHandlingType = "continue";
                } else if (RC === 1003) {
                    const exceededMaxQty = _.get(records, "exceeded_max_qty", {});
                    const exceededMaxQtyProductIds = Object.keys(exceededMaxQty);
                    const cart = _.cloneDeep(state.carts[pageHelper.getGid()]);
                    const exceededProductNames = [];
                    exceededMaxQtyProductIds.forEach((id) => {
                        let totalQty = 0;
                        let productName = "";
                        for (const product of cart) {
                            if (product.pid === Number(id)) {
                                totalQty += product.qty;
                                productName = product.nm;
                            }
                        }
                        let excessiveAmount = totalQty - exceededMaxQty[id];
                        for (const product of cart) {
                            if (product.pid === Number(id) && excessiveAmount > 0) {
                                let tempExcess = excessiveAmount;
                                excessiveAmount -= product.qty;
                                if (product.qty > tempExcess) {
                                    product.qty -= tempExcess;
                                } else {
                                    product.qty = 0;
                                }
                            }
                        }

                        // Remove all products that have no quantity
                        for (let i = 0; i < cart.length;) {
                            if (cart[i].qty === 0) {
                                cart.splice(i, 1);
                            } else {
                                i++;
                            }
                        }

                        exceededProductNames.push(productName);
                    });
                    state.carts[pageHelper.getGid()] = cart;

                    state.gDisplayModal = true;
                    state.gDisplayModalTitle = "warning";
                    state.gDisplayModalContent = "warning_max_purchase_qty_exceeded_msg";
                    state.gDisplayModalHandlingType = "max_qty_reached";
                    state.gDisplayModalPurchaseQtyThresholdData = exceededMaxQty;
                } else if (RC === 1004) {
                    const minQtyNotMet = _.get(records, "min_sale_qty_not_met", {});
                    const minQtyNotMetProductIds = Object.keys(minQtyNotMet);
                    const cart = _.cloneDeep(state.carts[pageHelper.getGid()]);
                    const qtyNotMetProductNames = [];
                    minQtyNotMetProductIds.forEach((id) => {
                        const qtyNotMetProduct = cart.find((product) => product.pid === Number(id));
                        if (qtyNotMetProduct) {
                            qtyNotMetProductNames.push(qtyNotMetProduct.nm);
                        }
                    });
                    state.carts[pageHelper.getGid()] = cart;

                    state.gDisplayModal = true;
                    state.gDisplayModalTitle = "warning";
                    state.gDisplayModalContent = "warning_min_sale_qty_not_met_msg";
                    state.gDisplayModalHandlingType = "min_qty_not_met";
                    state.gDisplayModalAllowCancel = true;
                    state.gDisplayModalPurchaseQtyThresholdData = minQtyNotMet;
                } else {
                    state = Object.assign(state, initialModalData);
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "unexpected_error";
                    state.displayModalHandlingType = "back_to_store";
                }
                // turn off isLoading on first load of getQuote
            } else {
                state = Object.assign(state, initialModalData);
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "unexpected_error";
                state.displayModalHandlingType = "back_to_store";
            }
            return state;
        },
        checkoutOrderSuccess: (state, { payload }) => {
            const RC = Number(_.get(payload, "checkoutResponse.RC", 400));
            const records = _.get(payload, "checkoutResponse.records", {});

            if (RC === 200) {
                const payMethod = _.get(_.cloneDeep(state), "paymentMethod", "");
                const redirectUrl = `${window.location.origin}/checkout/success?orderId=${records.ord_id}`;
                const wechatPayUrl = _.get(records, "payment.wechat.pay_url", "")
                    ? `${_.get(records, "payment.wechat.pay_url", "")}&directpay=true`
                    : false;
                const aliPayUrl = _.get(records, "payment.alipay.pay_url", "");
                const unionPayUrl = _.get(records, "payment.unionpay.pay_url", "");
                const unionPayExpresUrl = _.get(records, "payment.unionpayexpress.pay_url", "");
                const channelPayUrl = _.get(records, "payment.alipay.channel_pay_url", "");
                const payPalPayUrl = payMethod === "paypal_express" && `/paypal?orderId=${records.ord_id}`;
                const payUrl = aliPayUrl || wechatPayUrl || unionPayUrl || unionPayExpresUrl || payPalPayUrl || "";

                state = Object.assign(state, initialCustomerData);
                state = Object.assign(state, initialCartData);
                state.orderId = records.ord_id;
                state.orderStoreName = records.s_nm;
                state.backUrl = "";
                state.payUrl = payUrl ? payUrl + `&redirect=${redirectUrl}` : "";
                state.aliPayChannelPayUrl = channelPayUrl ? channelPayUrl + `&return_url=${redirectUrl}` : "";
                state.confirmCheckoutSuccess = true;
                state.grandTotal = records.grandTotal;
                state.paymentMethod = payMethod;
                helper.removeLocalStorage(`g${state.gid}`);
                state.tableNumber = "";
            } else if (RC === 400) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "invalid_request";
                state.displayModalHandlingType = "continue";
            } else if (RC === 405) {
                displayStoreOfflineModal(state);
            } else if (RC === 456) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "transaction_declined";
                state.displayModalHandlingType = "continue";
            } else if (RC === 459) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "price_over_limit";
                state.displayModalHandlingType = "continue";
            } else if (RC === 460) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "invalid_shp_mtd";
                state.displayModalHandlingType = "continue";
            } else if (RC === 461) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "invalid_credit_card";
                state.displayModalHandlingType = "continue";
            } else if (RC === 463) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "invalid_pay_mtd";
                state.displayModalHandlingType = "continue";
            } else if (RC === 464) {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "invalid_shp_mtd";
                state.displayModalHandlingType = "continue";
            } else if (RC === 1000) {
                const gid = pageHelper.getGid();
                const products = Array.isArray(_.get(_.cloneDeep(state), `products.${gid}`))
                    ? _.get(_.cloneDeep(state), `products.${gid}`)
                    : [];
                const cartProducts = Array.isArray(_.get(_.cloneDeep(state), `carts.${gid}`))
                    ? _.get(_.cloneDeep(state), `carts.${gid}`)
                    : [];
                const oosRecords = records?.oos || [];
                const { oosItems, oosPids } = helper.getOOSInfo(products, cartProducts, oosRecords);
                state.displayModal = true;
                state.displayModalTitle = "warning";
                state.displayModalHandlingType = "keep_available_items";
                state.displayModalContentType = "item_oos";
                state.displayModalContent = "item_oos";
                state.displayModalAllowCancel = false;
                state.displayOOSProduct = helper.getOOSOptionKeywords(oosItems);
                state.displayModalHandlingState = { oosPids, oosItems, oosRecords };
            } else if (RC === 4600) {
                state.displayModal = true;
                state.displayModalTitle = "payment_declined";
                state.displayModalContent = "too_many_card_attempt";
                state.displayModalHandlingType = "continue";
            } else if (RC === 4601) {
                state.displayModal = true;
                state.displayModalTitle = "payment_declined";
                state.displayModalContent = "too_many_checkout_attempt";
                state.displayModalHandlingType = "continue";
            } else if (RC === 4599) {
                state.displayModal = true;
                state.displayModalTitle = "payment_declined";
                state.displayModalContent = "credit_card_declined";
                state.displayModalHandlingType = "continue";
            } else {
                state.displayModal = true;
                state.displayModalTitle = "error";
                state.displayModalContent = "unsuccessful_checkout";
                state.displayModalHandlingType = "continue";
            }
        },
        checkoutInstantSuccess: (state, { payload }) => {
            //set up initial Data
            state = Object.assign(state, initialGiftCardData);
            state = Object.assign(state, initialModalData);
            state = Object.assign(state, initialPointsData);

            //get data from response
            const RC = Number(_.get(payload, "RC"));

            const order = _.get(payload, "records.order");
            const store = _.get(payload, "records.store");
            const product = _.get(payload, "records.product", {});
            const giftcard = _.get(payload, "records.giftcard");
            const ctotal = _.get(payload, "records.c_total");

            const gid = _.get(store, "gid") || _.get(order, "gid");
            const selectedGiftCard = _.get(giftcard, "code")
                ? {
                    gc: _.get(giftcard, "code"),
                    g_id: gid,
                    bk_val: _.get(giftcard, "balance"),
                    bal: _.get(giftcard, "balance"),
                }
                : null;
            const selectedCartProduct = product
                ? {
                    pid: _.get(product, "pid"),
                    qty: 1,
                    price: getPrice(product),
                    originalPrice: _.get(product, "pc"),
                    productType: _.get(product, "pt"),
                    nm: _.get(product, "nm"),
                    options: {},
                }
                : {};
            //if created an order
            if (!_.isEmpty(order)) {
                state = Object.assign(state, initialCustomerData);
                state = Object.assign(state, initialCartData);
                state.orderId = order.ord_id;
                state.orderStoreName = order.s_nm;
                state.backUrl = "";
                state.confirmCheckoutSuccess = true;
                helper.removeLocalStorage(`g${gid}`);
                state.tableNumber = "";
            } else {
                //if order has not been created update state based on response

                //store info and payment info
                const paymentMap = config.PAYMENT_METHOD_NUMERIC_MAPPING;
                state.gid = gid;
                state.backUrl = helper.getIndependentDomain() ? "/" : `/store/${gid}`;
                state.selectedGiftCard = selectedGiftCard;
                state.giftCards = [selectedGiftCard];
                state.shippingMethod = _.get(ctotal, "shp_mtd");
                state.carts[gid] = [selectedCartProduct];
                state.products[gid] = [product];
                state.shippingMethods[gid] = config.SHIPPING_MAPPING_TO_TEXT[_.get(ctotal, "shp_mtd")];
                state.storeInstance = store;
                state.countryCode = store?.country;
                state.requireUtensil = getRequireUtentsilDefaultValue(store);

                //ctotal info
                const grandTotal = _.get(ctotal, "g_total", 0);
                const tax = _.get(ctotal, "tax", 0);
                const discounts = _.get(ctotal, "dis_ttl", 0);
                state.shippingFee = initialState.shippingFee;
                state.shippingAddress = initialState.shippingAddress;
                state.pickupLocationId = initialState.pickupLocationId;
                state.allowedPeriods = [];
                state.deliveryDisplayDate = "";
                state.deliveryDate = "";
                state.deliveryTime = "";
                state.qid = _.get(ctotal, "qid", 0);
                state.priceInfo.grandTotal = parseFloat(grandTotal);
                state.priceInfo.tax = parseFloat(tax);
                state.priceInfo.discounts = parseFloat(discounts);
                state.priceInfo.couponDiscount = parseFloat(_.get(ctotal, "cpn_dis", 0));
                state.priceInfo.storeDiscount = parseFloat(_.get(ctotal, "store_dis", 0));
                state.priceInfo.surcharge = parseFloat(_.get(ctotal, "surcharge", 0));
                state.pointsBalance = parseFloat(_.get(ctotal, "p_bal", 0));
                state.giftCardsNum = _.get(ctotal, "gc_count", 0);
                state.priceInfo.subTotal = grandTotal + parseFloat(Math.abs(discounts)) - parseFloat(tax);
                state.paymentMethod =
                    grandTotal === 0
                        ? paymentMap.free
                        : _.get(giftcard, "code")
                            ? paymentMap.giftcard
                            : [
                                paymentMap.pay_later,
                                paymentMap.cash,
                                paymentMap.wechat_pay,
                                paymentMap.paypal_express,
                                paymentMap.alipay,
                                paymentMap.union_pay,
                                paymentMap.union_pay_express,
                            ].includes(Number(getInstantCheckoutParams()?.payment_method))
                                ? getInstantCheckoutParams()?.payment_method
                                : null;
                state.priceInfo.tipsPercent = 0;
                state.priceInfo.tips = 0;
                state.tipsButtonSelected = true;
                state.pickupLocations = Array.isArray(_.get(ctotal, "pickup_locations"))
                    ? _.get(ctotal, "pickup_locations")
                    : [];
                state.customerRole = _.get(ctotal, "customer_role", 0);
                state.tipsConfirm = true;

                //handle error
                if (RC === 400) {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "invalid_request_body";
                    state.displayModalHandlingType = "continue";
                }
                if (RC === 1000) {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "item_oos_all";
                    state.displayModalHandlingType = "remove_items_return_to_store";
                    state.displayModalKeywords = {
                        oos_items: _.get(product, "nm"),
                    };
                    state.displayModalHandlingState = { oosPids: [_.get(product, "pid")] };
                }
                if (RC === 1002) {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "item_oos_all";
                    state.displayModalHandlingType = "back_to_store";
                }
                if (RC === 4701) {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "no_giftcard";
                    state.displayModalHandlingType = "continue";
                }
                if (RC === 4702) {
                    state.displayModal = true;
                    state.displayModalTitle = "error";
                    state.displayModalContent = "giftcard_insuffienct_fund";
                    state.displayModalHandlingType = "continue";
                }
            }
        },
        clearAddress: (state) => {
            state.shippingAddress = {};
        },
        getAllowedPeriodsSuccess: (state, { payload }) => {
            const RC = payload?.RC;
            const periods = payload?.periods;
            const pickupLocations = payload?.pickup_locations;
            const store = payload?.store?.records;
            state.storeInstance = store;
            if (RC === 206) {
                // If no allowed periods returned for delivery
                state.displayModal = true;
                state.displayModalTitle = "distance_exceeded";
                state.displayModalContent = "exceed_delivery_distance_select";
                state.displayModalHandlingType = "distance_exceeded";
                state.displayModalKeywords = {
                    delivery_distance: getMaxDeliveryDistance(store),
                    distance_unit: getStoreDistanceUnit(store),
                };
                state.displayModalType = "error";
                state.shippingAddress = {};
            } else if (RC === 210) {
                // If no allowed periods returned for area based delivery
                state.displayModal = true;
                state.displayModalTitle = "invalid_delivery_zone_title";
                state.displayModalContent = "invalid_delivery_zone_content";
                state.displayModalContentType = "invalid_delivery_zone";
                state.displayModalHandlingType = "continue";
                state.displayModalType = "error";
                state.shippingAddress = {};
            } else {
                // Update state with allowed delivery periods
                const allowedPeriods = periods;
                if (!state.allowedPeriods || _.isEmpty(allowedPeriods)) {
                    state.allowedPeriods = allowedPeriods;
                }

                if (!_.isEmpty(periods)) {
                    if (state.displayDeliveryTimePicker) {
                        if (!state.deliveryDate) {
                            state.deliveryDisplayDate = Object.keys(allowedPeriods)?.[0];
                            state.deliveryDate = Object.keys(allowedPeriods)?.[0];
                            const time1 = allowedPeriods?.[Object.keys(allowedPeriods)?.[0]]?.[0];
                            const time2 = allowedPeriods?.[Object.keys(allowedPeriods)?.[0]]?.[1];
                            state.deliveryTime = time1 === ASAP && time2 ? time2 : time1;
                        }
                        // sets state for best date and time when new periods from eta_slots API changes
                        // occurs only when Time Picker is clicked
                        setBestTimeSlot(state, periods);
                    }
                    state.pickupLocations = [];
                    setBestTimeSlot(state, periods);
                }

                if (!_.isEmpty(pickupLocations)) {
                    // Update state with allowed pickup locations
                    state.pickupLocations = pickupLocations;
                    // Update state with allowed pickup locations
                    state.pickupLocations = pickupLocations;
                    const pickupLocation = _.get(state.pickupLocations, "0", {});
                    const id = _.get(pickupLocation, "id");
                    const times = _.isEmpty(_.get(pickupLocation, "timeslots"))
                        ? {}
                        : _.get(pickupLocation, "timeslots");
                    const date = _.get(Object.keys(times), "0");
                    const time = _.get(times, `${date}.0`);
                    if (
                        !state.pickupLocationId ||
                        !state.deliveryDate ||
                        !state.deliveryTime ||
                        !state.allowedPeriods
                    ) {
                        state.pickupLocationId = id;
                        state.allowedPeriods = times;
                        state.deliveryDisplayDate = date;
                        state.deliveryDate = date;
                        state.deliveryTime = time;
                    }
                    // sets state for best date and time for multiple location
                    // when new periods from eta_slots API changes
                    setBestTimeSlotMultipleLocations(state, pickupLocations);
                }
            }
        },
        toastEmptyCartWarning: (state, { payload }) => {
            state.displayModal = true;
            state.displayModalTitle = "error";
            state.displayModalContent = "item_error";
            state.displayModalHandlingType = "back_to_store";
            state.displayModalAllowCancel = false;

            helper.removeLocalStorage(`g${state.gid}`);
            helper.removeLocalStorage(`gs${state.gid}`);
            helper.removeLocalStorage(`gc${state.gid}`);
        },
        myUpsellsSuccess: (state, { payload }) => {
            state.discountedUpsellProducts = _.get(payload, "records", []);
        },
    },
});

export const {
    //to components
    setState,
    clearData,
    clearSesitiveData,
    updateTips,
    updateMinTips,
    updateDisplayPrice,
    saveDeliveryTime,
    updateShippingAddress,
    updateCheckoutCart,
    addCheckoutCartItem,
    removeCheckoutCartItem,
    removeAllCheckoutCartItemsByProductId,
    updateCheckoutCartMethod,
    clearAddress,
    updateCheckoutCartItem,
    //to service
    getStoreGiftCardsSuccess,
    checkoutCartSuccess,
    checkoutOrderSuccess,
    checkoutInstantSuccess,
    getStoreSucess,
    getUserProfileSuccess,
    getAllowedPeriodsSuccess,
    toastEmptyCartWarning,
    myUpsellsSuccess,
} = checkout.actions;

export default checkout.reducer;
