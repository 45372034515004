import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { setMerchantId, setClientId, setBNCode, setPaymentAction, setPaymentMode } from "components/ppcp-apple-pay-button/slice";
const initialState = {
    token: "",
    ppcpApplePay: false,
    ppcpGooglePay: false,
    ppcpPayPal: false,
    paypal_sdk: null,
    appleEnabled: false,
    googleEnabled: false,
    paypalEnabled: false,
};

const ppcpPlugin = createSlice({
    name: "ppcpPlugin",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        setPPCPToken: (state, { payload }) => {
            const token = _.get(payload, "token");
            state.token = token;
        },
        setPPCPState: (state, { payload }) => {
            state.ppcpApplePay = _.get(payload, "ppcpApplePay", state.ppcpApplePay);
            state.ppcpGooglePay = _.get(payload, "ppcpGooglePay", state.ppcpGooglePay);
            state.ppcpPayPal = _.get(payload, "ppcpPayPal", state.ppcpPayPal);
        },
        setPPCPEnableState: (state, { payload }) => {
            state.appleEnabled = _.get(payload, "appleEnabled", state.appleEnabled);
            state.googleEnabled = _.get(payload, "googleEnabled", state.googleEnabled);
            state.paypalEnabled = _.get(payload, "paypalEnabled", state.paypalEnabled);
        },
        setPayPalSdk: (state, { payload }) => {
            state.paypal_sdk = payload;
        },
    },
});

export const getPPCPTokens = (response, dispatch) => {
    const merchantId = _.get(response, "records.ppcp_merchantID");
    const clientId = _.get(response, "records.ppcp_clientID");
    const paymentAction = _.get(response, "records.ppcp_payment_action");
    const appleEnabled = _.get(response, "records.ppcp_apple_enable");
    const googleEnabled = _.get(response, "records.ppcp_google_enable");
    const paypalEnabled = _.get(response, "records.ppcp_paypal_enable");
    const ppcpEnabled = _.get(response, "records.ppcp_enable");
    const ppcpMode = _.get(response, "records.ppcp_mode");
    const ppcpBNCode = _.get(response, "records.ppcp_bncode");
    const ppcp3dsEnabled = _.get(response, "records.credit_card_3ds_enabled");
    if (merchantId && clientId) {
        dispatch(setMerchantId({ merchantId }));
        dispatch(setClientId({ clientId }));
        dispatch(setBNCode({ BNCode: ppcpBNCode }));
        dispatch(setPaymentAction({ PaymentAction: paymentAction }));
        dispatch(setPaymentMode({ mode: ppcpMode }));
        const EnableToken = {
            enable: ppcpEnabled,
            appleEnabled: appleEnabled,
            googleEnabled: googleEnabled,
            paypalEnabled: paypalEnabled,
            ppcp3dsEnabled: ppcp3dsEnabled
        };
        dispatch(setPPCPToken({ token: EnableToken }));
        dispatch(setPPCPEnableState({ appleEnabled, googleEnabled, paypalEnabled, ppcp3dsEnabled }));
    }
};


export const { setState, setPPCPToken, setPPCPState, setPPCPEnableState, setPayPalSdk } = ppcpPlugin.actions;

export default ppcpPlugin.reducer;
