import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    token: "",
    braintreeApple: false,
    braintreeCreditCard: false,
    braintreePayPal: false,
    braintreeGooglePay: false,
};

const braintree = createSlice({
    name: "braintree",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getTokenSuccess: (state, { payload }) => {
            const token = _.get(payload, "records.token");
            state.token = token;
            
        },
    },
});

export const { setState, getTokenSuccess } = braintree.actions;

export default braintree.reducer;
