import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    orderId: "",

};

const ppcp = createSlice({
    name: "ppcp",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getIDSuccess: (state, { payload }) => {
            const orderId = _.get(payload, "orderId");
            state.orderId = orderId;
        },
        setMerchantId: (state, { payload }) => {
            state.merchantId = _.get(payload, "merchantId");
        },
        setClientId: (state, { payload }) => {
            state.clientId = _.get(payload, "clientId");
        },
        setBNCode: (state, { payload }) => {
            state.BNCode = _.get(payload, "BNCode");
        },
        setPaymentAction: (state, { payload }) => {
            state.PaymentAction = _.get(payload, "PaymentAction");
        },
        setPaymentMode: (state, { payload }) => {
            state.mode = _.get(payload, "mode");
        },
    },
});

export const getOrderID = (state) => state.ppcp.orderId;
export const getMerchantId = (state) => state.ppcp.merchantId;
export const getClientId = (state) => state.ppcp.clientId;
export const getBNCode = (state) => state.ppcp.BNCode;
export const getPaymentAction = (state) => state.ppcp.PaymentAction;
export const getPaymentMode = (state) => state.ppcp.mode;
export const { setState, getIDSuccess, setMerchantId, setClientId, setBNCode, setPaymentAction, setPaymentMode } = ppcp.actions;

export default ppcp.reducer;
