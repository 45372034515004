import { combineReducers } from "@reduxjs/toolkit";
import settingsReducer from "../settings/slice";
import storesReducer from "../store-page/slice";
import orderDetailReducer from "../order-detail-page/slice";
import shoppingCartReducer from "../shopping-cart-page/slice";
import signInReducer from "../sign-in/slice";
import authReducer from "../auth/slice";
import signUpReducer from "../sign-up/slice";
import checkoutReducer from "../checkout-page/slice";
import ordersReducer from "../order-list-page/slice";
import payNowReducer from "../pay-now-page/slice";
import passwordResetReducer from "../password-reset/slice";
import emailResetReducer from "../email-reset/slice";
import accountReducer from "../account-page/slice";
import notificationsReducer from "../notifications/slice";
import checkoutSuccessReducer from "../checkout-success-page/slice";
import selfCheckoutReducer from "../self-checkout-page/slice";
import LocationReducer from "../location/slice";
import redirectReducer from "../redirect-page/slice";
import bindPhoneReducer from "../bind-phone-page/slice";
import setPasswordReducer from "../set-password-page/slice";
import addressBookReducer from "../address-book-page/slice";
import toastReducer from "components/toast/slice";
import bookmarkReducer from "../bookmark-page/slice";
import accountEditReducer from "../account-page-edit/slice";
import accountSettingsReducer from "../account-settings-page/slice";
import animatedButtonsReducer from "components/animated-cart-button/slice";
import errorDialogReducer from "components/error-handling-dialog/slice";
import homePageReducer from "../homepage/slice";
import storesListReducer from "../homepage/storesListSlice";
import storesSearchReducer from "../homepage/storesSearchSlice";
import productsSearchReducer from "../homepage/productSearchSlice";
import paypalReducer from "../paypal-page/slice";
import braintreeReducer from "components/braintree-plugin/slice";
import ppcpReducer from "components/ppcp-apple-pay-button/slice";
import ppcpOrderReducer from "components/ppcp-apple-pay-button/ppcp-order/slice";
import ppcpPluginReducer from "components/ppcp-plugin/slice";
import storeRecentlyViewedReducer from "../account-recently-viewed-page/storeRecentlyViewedSlice";
import productRecentlyViewedReducer from "../account-recently-viewed-page/productRecentlyViewedSlice";
import wechatPluginReducer from "../wechat-plugin/slice";
import sharePosterReducer from "components/poster-share-dialog/slice";
import refCodeReducer from "components/ref-code/slice";
import QRScannerReducer from "../qr-scanner/slice";
import StorageReducer from "../storage-assitant/slice";
import reviewBoost from "../review-pages/slice";


export const reducer = combineReducers({
    account: accountReducer,
    settings: settingsReducer,
    stores: storesReducer,
    orders: ordersReducer,
    orderDetail: orderDetailReducer,
    shoppingCart: shoppingCartReducer,
    signIn: signInReducer,
    auth: authReducer,
    signUp: signUpReducer,
    checkout: checkoutReducer,
    payNow: payNowReducer,
    passwordReset: passwordResetReducer,
    emailReset: emailResetReducer,
    notifications: notificationsReducer,
    checkoutSuccess: checkoutSuccessReducer,
    selfCheckout: selfCheckoutReducer,
    location: LocationReducer,
    redirect: redirectReducer,
    bindPhone: bindPhoneReducer,
    setPassword: setPasswordReducer,
    addressBook: addressBookReducer,
    toast: toastReducer,
    errorDialog: errorDialogReducer,
    bookmark: bookmarkReducer,
    accountEdit: accountEditReducer,
    animatedButtons: animatedButtonsReducer,
    accountSettings: accountSettingsReducer,
    homepage: homePageReducer,
    storesSearch: storesSearchReducer,
    productsSearch: productsSearchReducer,
    storesList: storesListReducer,
    paypal: paypalReducer,
    braintree: braintreeReducer,
    recentStores: storeRecentlyViewedReducer,
    recentProducts: productRecentlyViewedReducer,
    wechatPlugin: wechatPluginReducer,
    sharePoster: sharePosterReducer,
    refCode: refCodeReducer,
    QRScanner: QRScannerReducer,
    storage: StorageReducer,
    review: reviewBoost,
    ppcp: ppcpReducer,
    ppcpOrder: ppcpOrderReducer,
    ppcpPlugin: ppcpPluginReducer,
});
