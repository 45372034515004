import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
    status: "",

};

const ppcpOrder = createSlice({
    name: "ppcpOrder",
    initialState,
    reducers: {
        setState: (state, { payload }) => {
            state = Object.assign(state, payload);
        },
        getOrderSuccess: (state, { payload }) => {

            const status = _.get(payload, "status");
            state.status = status;
        },
    },
});

export const getOrderStatus = (state) => state.ppcpOrder.status;

export const { setState, getOrderSuccess } = ppcpOrder.actions;

export default ppcpOrder.reducer;
